import { Button } from 'components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { getContrastYIQ } from 'utils';
import { useTheme } from 'styles/theme';

import { ReactComponent as Back } from 'assets/svg/back.svg';

import './text.module.css';

type PageType = {
  editableText: string;
  id: number;
  priority: number;
  viewableText: string;
};
export interface TextWidgetProps {
  data: {
    id: number;
    content: PageType[];
    thumbnail: string;
    title: string;
    type: string;
  };
}

const TextWidget: React.FC<TextWidgetProps> = ({ data }) => {
  const [page, setPage] = useState<number>(0);

  const { mainColor, IconColor } = useTheme();

  const color = getContrastYIQ(mainColor);

  return (
    <div className="w-full bg-white p-4  min-h-screen">
      <div className="w-full flex justify-start  ">
        <Link className="py-5" to="/">
          <Back fill={'black'} />
          {/* <Back fill={color !== 'black' ? 'white' : 'black'} /> */}
        </Link>
      </div>

      {data.content.map((_page, index) => {
        if (page === index)
          return (
            <div key={index} className="w-full">
              <div
                className="animate-opacity min-h-70vh"
                style={{ color: color === 'white' ? mainColor : 'black' }}
                dangerouslySetInnerHTML={{ __html: _page.viewableText }}
              />
            </div>
          );
      })}

      <div className="w-full  my-5 flex-col flex justify-center items-center">
        <div className="flex justify-center ">
          {page !== 0 && (
            <Button
              style={{
                backgroundColor: mainColor,
              }}
              onClick={() =>
                setPage((prev) => {
                  prev--;
                  return prev;
                })
              }
              className="mx-3 transform -rotate-180  w-20 h-20 rounded-full bg-yellow-150 flex justify-center items-center"
            >
              <div
                style={{
                  borderColor: IconColor,
                }}
                className="w-4 h-4 border-t-2  border-black border-r-2 transform rotate-45 bg-transparent "
              />
            </Button>
          )}
          {page !== data.content.length - 1 && (
            <Button
              style={{
                backgroundColor: mainColor,
              }}
              onClick={() =>
                setPage((prev) => {
                  prev++;
                  return prev;
                })
              }
              className="mx-3  w-20 h-20 rounded-full  flex justify-center items-center"
            >
              <div
                style={{
                  borderColor: IconColor,
                }}
                className="w-4 h-4 border-t-2   border-r-2 transform rotate-45 bg-transparent "
              />
            </Button>
          )}
        </div>

        <div className="my-4 ">{page + 1 + '/' + data.content.length}</div>
      </div>
    </div>
  );
};

export default TextWidget;
