import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FieldError } from 'react-hook-form';

import { RequestResetPassword } from 'types/api/user';
import { Button, Input } from 'components';
import { useMutation } from 'react-query';
import gate from 'gate';
import { toast } from 'react-toastify';
import { useTheme } from 'styles/theme';
import { getContrastYIQ } from 'utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'store/reducers/user';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const router = useHistory();
  const { mutate, isLoading } = useMutation(
    async (data: RequestResetPassword) => {
      try {
        await gate.resetPassword(data);
        toast.success('Your password has been changed.');
      } catch ({ data }) {
        toast.error(data.detail.message);
      }
    },
  );
  const { t } = useTranslation();
  const { mainColor, backgroundColor } = useTheme();
  const ResetPassSchema = yup.object().shape({
    currentPassword: yup.string().required(t('field is required')),
    password: yup.string().required(t('field is required')),
  });
  const defValue: RequestResetPassword = { currentPassword: '', password: '' };
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(ResetPassSchema),
    defaultValues: defValue,
  });

  const onSubmit = (data: RequestResetPassword) => {
    mutate(data);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-8/12 ">
        <div className="w-full mt-10 flex justify-center items-center">
          <Controller
            as={Input}
            className="w-full"
            style={{
              backgroundColor:
                getContrastYIQ(backgroundColor) === 'black'
                  ? '#edf2f7'
                  : 'white',
            }}
            inputClassName="bg-white  rounded-2xl border-none p-3"
            type="password"
            name="currentPassword"
            error={((errors.currentPassword as unknown) as FieldError)?.message}
            title="Current Password"
            control={control}
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <Controller
            as={Input}
            className="w-full"
            inputClassName="bg-white  rounded-2xl border-none p-3"
            style={{
              backgroundColor:
                getContrastYIQ(backgroundColor) === 'black'
                  ? '#edf2f7'
                  : 'white',
            }}
            name="password"
            error={((errors.password as unknown) as FieldError)?.message}
            type="password"
            title="Password"
            control={control}
          />
        </div>

        <div className="w-full justify-start items-center flex py-3">
          <Button
            style={{
              backgroundColor: mainColor,
              color: getContrastYIQ(mainColor),
            }}
            isLoading={isLoading}
            className="text-14 rounded-full px-3 py-2 "
          >
            {t('Reset Password')}
          </Button>
        </div>
      </form>

      <div className="justify-start w-full px-24 ml-3 flex py-3">
        <Button
          onClick={() => {
            localStorage.removeItem('token');
            dispatch(logout(''));
            router.replace('/login');
          }}
          style={{
            backgroundColor: mainColor,
            color: getContrastYIQ(mainColor),
          }}
          className=" uppercase text-14 rounded-full px-3 py-2 "
        >
          {t('logout')}
        </Button>
      </div>
    </>
  );
};

export default ResetPassword;
