import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Input, Layout, Spinner } from 'components';

import gate from 'gate';

import logo from 'assets/svg/logo.svg';
import { RequestForgetPassword } from 'types/api/auth';

const WebApp: React.FC = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
  });
  const { errors, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const _postData = async (data: RequestForgetPassword) =>
    await gate.forgetPassworfRequest(data);
  const { data, error, isLoading, mutate: postData } = useMutation(_postData, {
    onSuccess: () => {},
  });

  useEffect(() => {
    if (data) {
      toast.success(data?.message);
    }
    if (error) {
      toast.error((error as any)?.data?.message || 'Unexpcted error!');
    }
  }, [data, error]);

  const onSubmit = (data: RequestForgetPassword) => {
    postData(data);
  };

  return (
    <Layout className=" min-h-screen justify-end items-end bg-yellow-150">
      <div className="w-full animate-opacity  pb-32 flex justify-center items-center">
        <img className="h-8" src={logo} alt="..." />
      </div>
      <div className="w-full flex flex-col justify-center items-center ">
        <div className="w-full text-center font-bold text-lg">
          {t('Forgot your password')}
        </div>
        <div className="w-full  text-left pb-16 p-3">
          {t('title of forget password')}{' '}
        </div>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full justify-center px-3">
            <Controller
              as={Input}
              title={t('Email')}
              name="email"
              type="email"
              labelClassName="pb-1"
              error={((errors?.email as unknown) as FieldError)?.message}
              placeholder="Fill in your company mail"
              className="w-full px-6"
              control={control}
              inputClassName="w-full text-12 lg:text-base rounded-full border-none "
            />
          </div>

          <div className="flex pb-24 w-full justify-center items-center p-8">
            <button
              type="submit"
              className="rounded-full text-white bg-black px-12 py-4  "
            >
              {isLoading ? <Spinner className="w-6 h-6" /> : t('To request')}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default WebApp;
