import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';

import { ChatSpinner, Layout } from 'components';
import gate from 'gate';

import imgPlaceholder from 'assets/images/loading.gif';
import send from 'assets/svg/send.svg';
import { selectUser } from 'store/selectors/user';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { RequestRegister } from 'types/api/auth';
import { getContrastYIQ, getDownloadAddress } from 'utils';
export interface RegisterChatBotProps {}
interface RouteParams {
  company: string;
  department: string;
}

type messageType = {
  text: string;
  align: string;
  loading?: boolean;
  type?: string;
};
type department = {
  id: number;
  name: string;
};
const RegisterChatBot: React.FC<RegisterChatBotProps> = () => {
  const div = useRef<any>();
  const [selectedDay, setSelectedDay] = useState<DayValue>();
  const [scrollToLastMessage, setScrollToLastMessage] = useState<boolean>(
    false,
  );
  const [fakeMsgs, setFakeMsgs] = useState<messageType[]>([]);
  const [text, setText] = useState<messageType>({ text: '', align: '' });
  const { company } = useParams<RouteParams>();
  const { loggedIn } = useSelector(selectUser);
  const { isLoading, data, error } = useQuery('companyDetails', async () => {
    return await gate.companyDetails(company);
  });
  const { departments, color } = data ?? {};
  const register = async (data: RequestRegister) => {
    return await gate.register(data);
  };

  const {
    isLoading: registerLoading,
    data: registerResponse,
    error: registerError,
    mutate: postData,
  } = useMutation(register);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fakeMsgs[lastMessage].type?.includes('oading')) return;
    if (fakeMsgs[lastMessage].align === 'right') return;
    if (fakeMsgs[lastMessage].type === 'startTimeMessage') {
      return setFakeMsgs((prev) => {
        return [
          ...prev,
          {
            text: `${selectedDay?.year}/${selectedDay?.month}/${selectedDay?.day}`,
            align: 'right',
            type: 'startTime',
          },
        ];
      });
    }
    if (text.text === '') return;
    setFakeMsgs((prev) => {
      return [...prev, text];
    });
    setText({ text: '', align: '' });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lastMessage = fakeMsgs[fakeMsgs.length - 1];
    switch (lastMessage.type) {
      case 'emailMessage' || 'incorrectEmail':
        setText({ align: 'right', text: e.target.value, type: 'email' });
        break;

      case 'firstNameMessage':
        setText({
          align: 'right',
          text: e.target.value,
          type: 'firstname',
        });

        break;
      case 'lastNameMessage':
        setText({
          align: 'right',
          text: e.target.value,
          type: 'lastname',
        });
        break;
      case 'departmentMessage':
        setText({
          align: 'right',
          text: e.target.value,
          type: 'department',
        });
        break;
      case 'passwordMessage':
        setText({
          align: 'right',
          text: e.target.value,
          type: 'password',
        });
        break;

      default:
        break;
    }
  };
  const scrollToElement = () => {
    const elmnt = div.current;
    if (elmnt && fakeMsgs.length > 3) {
      elmnt.scrollIntoView(true);
    }
  };
  useEffect(() => {
    scrollToElement();
  }, [scrollToLastMessage]);

  const lastMessage = fakeMsgs.length - 1;
  useEffect(() => {
    setScrollToLastMessage(!scrollToLastMessage);
    if (!fakeMsgs.length) {
      setFakeMsgs((prev) => [
        ...prev,
        { text: '', align: 'left', loading: true, type: 'firstLoading' },
      ]);
    }

    if (!fakeMsgs.length) return;
    if (fakeMsgs[lastMessage].type === 'firstLoading') {
      if (data) {
        setTimeout(() => {
          setFakeMsgs((prev) => {
            let deleteDuplicateMasssege = prev.filter(
              (msg) => msg.loading !== true,
            );
            return [
              ...deleteDuplicateMasssege,
              {
                text: data?.registerQuestions[0].welcome,
                align: 'left',
                loading: false,
                type: 'hi',
              },
            ];
          });
        }, 1000);
      }
    }
    if (fakeMsgs[lastMessage].type === 'hi') {
      if (data) {
        setFakeMsgs((prev) => [
          ...prev,
          {
            text: '',
            align: 'left',
            loading: true,
            type: 'loading',
          },
        ]);
      }
      setTimeout(() => {
        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          return [
            ...deleteDuplicateMasssege,
            {
              text: data.registerQuestions[0].firstname,
              align: 'left',
              loading: false,
              type: 'firstNameMessage',
            },
          ];
        });
      }, 1000);
    }
    if (fakeMsgs[lastMessage].type === 'firstname') {
      if (data) {
        setFakeMsgs((prev) => [
          ...prev,
          {
            text: '',
            align: 'left',
            loading: true,
            type: 'loading',
          },
        ]);
      }
      setTimeout(() => {
        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          return [
            ...deleteDuplicateMasssege,
            {
              text: data.registerQuestions[0].lastname,
              align: 'left',
              loading: false,
              type: 'lastNameMessage',
            },
          ];
        });
      }, 1000);
    }

    if (fakeMsgs[lastMessage].type === 'password') {
      setFakeMsgs((prev) => [
        ...prev,
        {
          text: '',
          align: 'left',
          loading: true,
          type: 'loading',
        },
      ]);
      setTimeout(() => {
        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          return [
            ...deleteDuplicateMasssege,
            {
              text: data?.registerQuestions[0].department,
              align: 'left',
              loading: false,
              type: 'departmentMessage',
            },
          ];
        });
      }, 1000);
    }
    if (fakeMsgs[lastMessage].type === 'department') {
      setFakeMsgs((prev) => [
        ...prev,
        {
          text: '',
          align: 'left',
          loading: true,
          type: 'loading',
        },
      ]);
      setTimeout(() => {
        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          return [
            ...deleteDuplicateMasssege,
            {
              text: data?.registerQuestions[0].startTime,
              align: 'left',
              loading: false,
              type: 'startTimeMessage',
            },
          ];
        });
      }, 1000);
    }
    if (fakeMsgs[lastMessage].type === 'startTime') {
      const filterMsgs = fakeMsgs.filter((msg) => msg.align === 'right');
      const email = filterMsgs.find((email) => email.text.includes('@'));
      const lastname = filterMsgs.find((msg) => msg.type === 'lastname');
      const firstname = filterMsgs.find((msg) => msg.type === 'firstname');
      const startTime = filterMsgs.find((msg) => msg.type === 'startTime');
      const password = filterMsgs.find((msg) => msg.type === 'password');
      const department = fakeMsgs.find((msg) => msg.type === 'department');

      const formData: RequestRegister = {
        firstName: firstname!.text,
        lastName: lastname!.text,
        password: password!.text,
        departmentId: Number(department!.text),
        startTime: new Date(startTime!.text),
        email: email!.text,
      };
      setFakeMsgs((prev) => [
        ...prev,
        {
          text: '',
          align: 'left',
          loading: true,
          type: 'registerLoading',
        },
      ]);
      postData(formData);
    }

    if (fakeMsgs[lastMessage].type === 'registerLoading') {
      if (registerError) {
        return setFakeMsgs((prev) => {
          return [
            {
              align: 'left',
              text: (registerError as any)?.data?.detail?.message,
              type: 'hi',
            },
          ];
        });
      }
      if (registerResponse) {
        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          return [
            ...deleteDuplicateMasssege,
            {
              align: 'left',
              text: (registerResponse as any).message ?? '',
              type: 'end',
            },
          ];
        });
      }
    }
    if (fakeMsgs[lastMessage].type === 'lastname') {
      const originalEmailMessage = data?.registerQuestions[0].email;
      const firstname = fakeMsgs.filter((msg) => msg.type === 'firstname');

      const emailMessage = originalEmailMessage.replace(
        /\[.*?\]\s?/g,
        capitalize(firstname[0].text) + ' ',
      );

      setFakeMsgs((prev) => [
        ...prev,
        {
          text: '',
          align: 'left',
          loading: true,
          type: 'loading',
        },
      ]);
      setTimeout(() => {
        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          return [
            ...deleteDuplicateMasssege,
            {
              text: emailMessage,
              align: 'left',
              loading: false,
              type: 'emailMessage',
            },
          ];
        });
      }, 1000);
    }

    if (fakeMsgs[lastMessage].type === 'email') {
      setFakeMsgs((prev) => [
        ...prev,
        {
          text: '',
          align: 'left',
          loading: true,
          type: 'loading',
        },
      ]);
      setTimeout(() => {
        const invalidEmailMessage = data?.registerQuestions[0].incorrectEmail;
        const passwordMessage = data?.registerQuestions[0].password;

        setFakeMsgs((prev) => {
          let deleteDuplicateMasssege = prev.filter(
            (msg) => msg.loading !== true,
          );
          const checkMail = prev[prev.length - 2];
          const message = checkMail.text.includes('@')
            ? passwordMessage
            : invalidEmailMessage;

          return [
            ...deleteDuplicateMasssege,
            {
              text: message,
              align: 'left',
              loading: false,
              type: checkMail.text.includes('@')
                ? 'passwordMessage'
                : 'incorrectEmail',
            },
          ];
        });
      }, 1000);
    }
  }, [
    fakeMsgs,
    data,
    isLoading,
    registerResponse,
    registerError,
    registerLoading,
  ]);

  return (
    <Layout>
      <div className="flex  bg-white min-h-screen relative  flex-col">
        <div
          style={{ backgroundColor: color }}
          className="top-0 z-10 py-4 fixed w-full lg:min-w-600 max-w-600  flex justify-start pl-5"
        >
          <div className="flex  justify-start items-center">
            <div className="flex justify-center mr-2 items-center">
              <img
                src={
                  data?.botAvatar
                    ? getDownloadAddress(data?.botAvatar)
                    : imgPlaceholder
                }
                alt="...."
                className="w-54 h-54 object-cover  rounded-full"
              />
            </div>
            <div>
              <div
                className="text-2xl font-bold"
                style={{ color: getContrastYIQ(color ?? '#f2f2f2') }}
              >
                {data?.botName}
              </div>

              <div
                className="text-sm "
                style={{ color: getContrastYIQ(color ?? '#f2f2f2') }}
              >
                {data?.name}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pb-24 pt-24  transition-all duration-500  flex flex-col">
          {fakeMsgs.map((message, index) =>
            message.align === 'left' ? (
              <div
                key={index}
                className="w-full flex my-3 chat-animation justify-start px-8"
              >
                <img
                  src={
                    data?.botAvatar
                      ? getDownloadAddress(data?.botAvatar)
                      : imgPlaceholder
                  }
                  alt="..."
                  className=" min-w-38 object-cover w-38 messageTypeect-cover  mr-4 h-38 rounded-full"
                />

                {message.loading ? (
                  <div className="px-5 py-4">
                    <ChatSpinner />
                  </div>
                ) : (
                  <div className="bg-gray-450 text-12 font-semibold shadow-large rounded-2xl text-black px-5 py-1">
                    {message.type === 'end' ? (
                      <Link className=" " to={`/${company}/login`}>
                        <div className="flex flex-col cursor-pointer">
                          <div className="flex">{message.text}</div>
                          <div className="flex bg-black text-white transform transition-all duration-300 hover:scale-110 text-center justify-center items-center  py-2 px-4 rounded-full">
                            Login
                          </div>
                        </div>
                      </Link>
                    ) : message.type === 'departmentMessage' ? (
                      <>
                        <div className="py-2  font-bold pb-2">
                          {data?.registerQuestions[0].department}
                        </div>

                        <div className="flex -ml-2 flex-col py-2 ">
                          {departments?.map((department: department) => {
                            const { id, name } = department;
                            return (
                              <label
                                key={name}
                                htmlFor={String(id)}
                                className="container flex justify-start mb-2"
                              >
                                <input
                                  onChange={(e) => {
                                    return setFakeMsgs((prev) => [
                                      ...prev,
                                      {
                                        text: String(e.target.value),
                                        type: 'department',
                                        align: '',
                                      },
                                    ]);
                                  }}
                                  className="hidden"
                                  id={String(id)}
                                  value={id}
                                  type="radio"
                                  name="department"
                                />
                                <div className="checkmark transition-all dration-200 relative flex justify-center items-center rounded-full w-5 h-5 min-w-20px ">
                                  <div className=" border-b-2 w-2 h-3 border-r-2 border-white absolute top-3 right-5 bg-transparent transform rotate-45 " />
                                </div>
                                <div className="w-full  text-left uppercase border-b border-gray-500 ml-1 pl-2  text-black">
                                  {name}
                                </div>
                              </label>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      message.text
                    )}
                  </div>
                )}
              </div>
            ) : (
              message.align === 'right' && (
                <div key={index} className="w-full my-3 pr-3 flex justify-end">
                  <div
                    style={{
                      backgroundColor: color,
                      color: getContrastYIQ(color),
                    }}
                    className=" chat-animation font-semibold shadow-large text-black px-8 py-4 rounded-full"
                  >
                    {message.type === 'password' ? '**********' : message.text}
                  </div>
                </div>
              )
            ),
          )}

          <div ref={div} className="h-0  w-full " />
        </div>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="bottom-0 bg-white max-w-600  fixed justify-center items-center px-4  w-full py-5 flex "
        >
          {fakeMsgs[lastMessage]?.type === 'startTimeMessage' ? (
            <DatePicker
              wrapperClassName="w-full"
              value={selectedDay}
              onChange={setSelectedDay}
              colorPrimary={color}
              renderInput={({ ref }: any) => (
                <input
                  readOnly
                  className="shadow-large w-full outline-none     rounded-lg px-3 py-4 "
                  placeholder="Type something"
                  ref={ref} // necessary
                  value={
                    selectedDay
                      ? `${selectedDay.year}/${selectedDay.month}/${selectedDay.day}`
                      : ''
                  }
                />
              )}
            />
          ) : (
            <input
              disabled={
                !!registerResponse ||
                fakeMsgs[lastMessage]?.type === 'departmentMessage'
              }
              type={
                fakeMsgs[lastMessage]?.type === 'passwordMessage'
                  ? 'password'
                  : fakeMsgs[lastMessage]?.type === 'emailMessage'
                  ? 'email'
                  : 'text'
              }
              value={text.text}
              onChange={handleChange}
              className="shadow-large w-10/12 outline-none     rounded-lg px-3 py-4 "
              placeholder="Type something"
            />
          )}
          <button
            type="submit"
            disabled={
              fakeMsgs[lastMessage]?.type === 'startTimeMessage' &&
              !!!selectedDay
            }
            style={{ backgroundColor: color }}
            className="w-12 cursor-pointer h-12 min-w-12 rounded-full mx-2 flex justify-center items-center "
          >
            <img className="w-4 h-4" src={send} />
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default RegisterChatBot;
