import React from 'react';
import Player from 'react-player';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Back } from 'assets/svg/back.svg';
import { Button } from 'components';
import Widget from './WidgetItem';
import { getContrastYIQ, getDownloadAddress } from 'utils';
import { useTheme } from 'styles/theme';
export interface MediaWidgetProps {
  data: {
    id: number;
    text: string;
    thumbnail: string;
    title: string;
    type: 'Media' | 'Audio';
    content: {
      filename: string;
      id: number;
      priority: number;
      url: string;
    };
  };
}

const MediaWidget: React.FC<MediaWidgetProps> = ({ data }) => {
  const { mainColor } = useTheme();
  const router = useHistory();

  const url =
    data.type === 'Media'
      ? data.content.url
        ? data.content.url
        : getDownloadAddress(data.content.filename)
      : getDownloadAddress(data.content.filename);

  return (
    <div className="w-full py-10 justify-center  flex-col flex ">
      <div className="w-full pb-20 ">
        <Button onClick={() => router.goBack()}>
          <Back
            fill={getContrastYIQ(mainColor) === 'black' ? 'white' : 'black'}
          />
        </Button>
      </div>
      <div className="flex min-h-full justify-center items-center">
        <Player
          url={url}
          controls
          config={{
            youtube: {
              playerVars: { modestbranding: 1 },
            },
          }}
        />
      </div>
    </div>
  );
};

export default MediaWidget;
