import React from 'react';
import { Layout, LoadingDots } from 'components';
import { useTheme } from 'styles/theme';

const LoadingPage: React.FC = () => {
  const { backgroundColor } = useTheme();

  return (
    <Layout footer={false}>
      <div
        style={{ backgroundColor: backgroundColor ?? '#ffca29' }}
        className="w-full flex min-h-screen justify-center items-center "
      >
        <LoadingDots />
      </div>
    </Layout>
  );
};

export default LoadingPage;
