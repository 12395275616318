import React from 'react';
import oval from 'assets/images/Oval.jpg';
import { ReactComponent as Letter } from 'assets/svg/letter.svg';
import { ReactComponent as Linkdin } from 'assets/svg/in.svg';
import { Colleague } from 'types/api/user';
import { getDownloadAddress } from 'utils';
type Prop = {
  data: Colleague;
};
const Card: React.FC<Prop> = ({ data }) => {
  return (
    <div className="bg-white relative overflow-hidden shadow-sm h-120 w-199 rounded-lg p-1 grid grid-cols-2">
      <div className="w-full h-full flex justify-center items-center ">
        <img
          className="rounded-full object-scale-down w-90 h-90"
          src={getDownloadAddress(data.avatar)}
          alt="..."
        />
      </div>
      <div className="ml-1  flex flex-col">
        <div className="text-black pt-1 break-words  font-bold text-10  w-2/3">
          {data.name}
        </div>
        <div className="text-gray-350 mt-1 text-10 mb-3 w-3/4">{data.role}</div>
        <div className="flex justify-start items-center ">
          <div className="flex absolute bottom-0 mb-4 justify-center pl-3 pt-2 items-center">
            <div className="flex justify-center  items-center ">
              <a href={`mailto:${data.email}`}>
                <Letter />
              </a>
            </div>
            <div className=" ml-2 flex justify-center items-center">
              <a href={data.linkedin}>
                <Linkdin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
