import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Back } from 'assets/svg/back.svg';

import { Button } from 'components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TodoItem } from '..';
import gate from 'gate';
import { useMutation, useQueryClient } from 'react-query';
import { RequestDoneTodo } from 'types/api/widget';
import { getContrastYIQ } from 'utils';
import { useTheme } from 'styles/theme';

interface TodoWidgetProps {
  data: any;
}

const TodoWidget: React.FC<TodoWidgetProps> = ({ data }) => {
  const router = useHistory();
  const { mainColor } = useTheme();
  const { id } = useParams<{ id: string }>();
  const { content } = data ?? {};
  const location = useLocation();
  const isCompleted = new URLSearchParams(location.search).get('completed');

  const { t } = useTranslation();
  const cache = useQueryClient();

  const { mutate: completeWidget } = useMutation(
    async (data: { isCompleted: boolean }) => {
      try {
        await gate.completeWidget(Number(id), data);
        cache.invalidateQueries('home');
        cache.invalidateQueries(`widget/${id}`);
      } catch (error) {}
    },
  );
  const [todos, setTodos] = useState<any[]>(content);
  const { mutate: TodoDone } = useMutation(
    async (_data: RequestDoneTodo) => {
      try {
        await gate.todoWidgetDone(data.id, _data);
      } catch (error) {}
    },
    {
      onSuccess: () => cache.invalidateQueries(`widget/${data.id}`),
    },
  );

  const completed = todos.filter((todo) => todo.done === false).length === 0;
  const onComplete = () => {
    if (completed) {
      return completeWidget({ isCompleted: true });
    } else {
      completeWidget({ isCompleted: false });
    }
  };

  useEffect(() => {
    onComplete();
  }, [todos]);

  const handleClick = (index: number, taskId: number) => {
    const newTodos = [...todos];

    newTodos[index].done = !newTodos[index].done;

    setTodos(newTodos);
    TodoDone({
      taskId: taskId,
      done: newTodos[index].done,
    });
  };

  return (
    <div className="full pt-4 min-h-screen flex flex-col justify-start items-center ">
      <div className="w-full justify-start p-4">
        <Button onClick={() => router.goBack()}>
          <Back
            fill={getContrastYIQ(mainColor) === 'black' ? 'white' : 'black'}
          />
        </Button>
      </div>

      <div className=" font-bold text-5xl">{t('To-Do')}</div>

      <div className="w-full pt-20 px-6">
        {todos?.map((todo, index) => {
          return (
            <TodoItem
              key={todo.id}
              {...todo}
              index={index}
              handleClick={handleClick}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TodoWidget;
