import React from 'react';
import cn from 'classnames';

import { useTheme } from 'styles/theme';
import { getContrastYIQ } from 'utils';

import classes from './widget-item.module.css';

import { ReactComponent as Tick } from 'assets/svg/tick.svg';
export interface WidgetProps {
  tick?: boolean;
  title?: string;
  imgUrl?: string;
  className?: string;
  type?: string;
  text?: string;

  lastItemColor?: string;
}

const Widget: React.FC<WidgetProps> = ({
  tick,
  title,
  lastItemColor,
  imgUrl,
  type,
  text = '',
  className,
}) => {
  const { backgroundColor, mainColor } = useTheme();
  return (
    <div
      className={`flex w-full  relative flex-col justify-center items-center ${className}`}
    >
      {tick && (
        <span
          style={{
            border: `2px solid ${backgroundColor}`,
            backgroundColor: mainColor,
          }}
          className="w-7 h-7 absolute -top-10 -right-10 flex justify-center items-center   rounded-full"
        >
          <Tick
            stroke={getContrastYIQ(mainColor) === 'black' ? 'black' : 'white'}
          />
        </span>
      )}
      {imgUrl ? (
        <img
          src={imgUrl}
          alt="..."
          className="rounded-lg object-cover shadow-md min-w-150 w-150 h-120"
        />
      ) : (
        <div
          style={{
            backgroundColor: lastItemColor === 'black' ? 'white' : mainColor,
            wordBreak: 'normal',
            fontSize: getFontSize(text),
          }}
          className={cn(
            classes['text'],
            {
              'text-black': lastItemColor === 'black',
              'text-white': lastItemColor !== 'black',
            },
            'flex shadow-md rounded-md p-2 break-all justify-center overflow-hidden  whitespace-normal  minw-150 w-150 h-120  items-center text-24 font-bold',
          )}
        >
          {text}
        </div>
      )}
      <div className="mt-2 w-full  text-14 text-left">
        {getShortTitle(title || '')}
      </div>
    </div>
  );
};

export default Widget;

function getShortTitle(title: string) {
  if (title.length > 20) {
    return `${title.substring(0, 20)}...`;
  }
  return title;
}

function getFontSize(text: string) {
  if (text.length > 25) {
    return '0.5rem';
  }
  if (text.length > 20) {
    return '0.75rem';
  }
  if (text.length > 15) {
    return '1rem';
  }

  return '1.25rem';
}
