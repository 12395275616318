import * as yup from 'yup';
import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Spinner } from 'components';
import logo from 'assets/svg/logo.svg';
import gate from 'gate';

const Form: React.FC = () => {
  const { t } = useTranslation();
  const _sendMail = async (data: any) => {
    try {
      const res = await gate.sendMail(data);
      toast.success('success');
      console.log('res:', res);
    } catch (err) {
      toast.error('something wrong');
      console.error('error:', err);
    }
  };

  const { isLoading, mutate: sendMail } = useMutation(_sendMail);
  const schema = yup.object().shape({
    name: yup.string().required(t('field is required')),
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
    description: yup.string().required(t('field is required')),
    company: yup.string().required(t('field is required')),
    // phone: yup.string().required(t("field is required")),
  });
  const { errors, register, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      description: '',
      company: '',
      phone: '',
    },
  });
  const onSubmit = (data: any) => sendMail(data);

  return (
    <div className="flex  flex-col">
      <div className="flex flex-col bg-white px-3 lg:px-20 lg:p-20">
        <div className="w-full lg:text-3xl text-36 pl-2 lg:mb-10  py-10 lg:py-0 text-black font-bold text-left">
          {t('Contact Form')}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid w-full lg:grid-cols-2">
            <div className="flex  flex-col lg:p-2">
              <div className="p-1  ">
                <Controller
                  as={Input}
                  name="name"
                  title={t('name')}
                  labelClassName="pb-3 lg:text-2xl text-24"
                  error={((errors?.name as unknown) as FieldError)?.message}
                  inputClassName="py-4 rounded-full"
                  control={control}
                />
              </div>
              <div className="p-1  ">
                <Controller
                  as={Input}
                  name="company"
                  title={t('company')}
                  labelClassName="pb-3 lg:text-2xl text-24"
                  error={((errors?.company as unknown) as FieldError)?.message}
                  inputClassName="py-4 rounded-full"
                  control={control}
                />
              </div>
              <div className="p-1  ">
                <Controller
                  as={Input}
                  name="email"
                  title={t('email')}
                  labelClassName="pb-3 lg:text-2xl text-24"
                  error={((errors?.email as unknown) as FieldError)?.message}
                  inputClassName="py-4 rounded-full"
                  control={control}
                />
              </div>
              <div className="p-1  ">
                <Controller
                  as={Input}
                  name="phone"
                  title={t('phone')}
                  labelClassName="pb-3 lg:text-2xl text-24"
                  error={((errors?.phone as unknown) as FieldError)?.message}
                  inputClassName="py-4 rounded-full"
                  control={control}
                />
              </div>
            </div>

            <div className="flex flex-col lg:px-10 px-3  ">
              <div className=" flex flex-col ">
                <label className="w-full  lg:text-2xl text-24 pb-5 py-2 text-left text-black font-bold">
                  {t('description')}
                </label>

                <textarea
                  ref={register}
                  name="description"
                  className="rounded-2xl p-3 focus:outline-none h-64 border border-black"
                ></textarea>
              </div>
              {errors.description && (
                <p className="w-full pt-1 text-center text-sm text-red-600">
                  {((errors?.description as unknown) as FieldError)?.message}
                </p>
              )}
              <div className="lg:pt-28 lg:mt-3 py-3  w-full">
                <button className="bg-green-450 shadow-lg  flex justify-center items-center focus:outline-none border-b-4 py-2 lg:text-3xl text-24 border-black rounded-full text-black w-full">
                  {isLoading ? <Spinner fill="black" /> : t('send')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="lg:px-16 px-3 justify-between flex bg-yellow-150  items-center w-full py-10">
        <img className="lg:h-6 h-4" src={logo} alt="..." />

        <a
          target="_blunk"
          href="/privacy_statement_22-12-2020 (1).pdf"
          className="font-bold capitalize"
        >
          {t('Privacy')}
        </a>
      </div>
    </div>
  );
};

export default Form;
