import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getContrastYIQ } from 'utils';
import { useTheme } from 'styles/theme';

import HomeIcon from 'assets/svg/homeIcon';
import InfoIcon from 'assets/svg/infoIcon';
import CustomerIcon from 'assets/svg/customerIcon';
const Footer: React.FC = () => {
  const { IconColor, mainColor } = useTheme();
  const { pathname: path } = useLocation();
  const homeActive = path === '/';
  const infoActive = path === '/faq';
  const customerActive = path === '/profile' || path === '/settings';

  const color = useMemo(() => {
    return getContrastYIQ(mainColor) === 'black' ? 'black' : 'white';
  }, [mainColor]);

  return (
    <div
      style={{ backgroundColor: mainColor }}
      className=" lg:min-w-600 max-w-600 bottom-0 z-999 fixed w-full"
    >
      <div className="w-full grid grid-cols-3 py-7">
        <Link to="/" className="flex justify-center ou items-center    ">
          <HomeIcon fill={homeActive ? IconColor : color} />
        </Link>

        <Link
          to="/faq"
          className="flex justify-center items-center  cursor-pointer "
        >
          <InfoIcon fill={infoActive ? IconColor : color} />
        </Link>

        <Link
          to="/profile"
          className="flex justify-center items-center  cursor-pointer "
        >
          <CustomerIcon fill={customerActive ? IconColor : color} />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
