import { Button } from 'components';
import React from 'react';

import { ReactComponent as Tick } from 'assets/svg/tick.svg';
import { useTheme } from 'styles/theme';
import { getContrastYIQ } from 'utils';
interface TodoItemProps {
  handleClick: Function;
  done: boolean | number;
  task: string;
  index: number;
  id: number;
}

const TodoItem: React.FC<TodoItemProps> = ({
  done,
  task,
  handleClick,
  index,
  id,
}) => {
  const { mainColor, backgroundColor } = useTheme();
  return (
    <div className="flex my-2 w-full">
      <div className=" flex justify-center items-center  w-2/12">
        <Button
          style={{ backgroundColor: done ? mainColor : 'white' }}
          onClick={() => handleClick(index, id)}
          className=" flex justify-center border border-black transition-all duration-100 items-center rounded-full w-8 h-8"
        >
          <div className="border-b-2 border-r-2 transform rotate-45 border-white bg-transparent w-3 mb-1 h-5" />
          {/* <Tick /> */}
        </Button>
      </div>
      <div
        style={{
          backgroundColor: done
            ? mainColor
            : getContrastYIQ(backgroundColor) === 'black'
            ? '#edf2f7'
            : 'white',

          color: done ? getContrastYIQ(mainColor) : 'black',
        }}
        className=" rounded-lg  transition-all duration-100 text-xl px-5 py-3  w-10/12"
      >
        {task}
      </div>
    </div>
  );
};

export default TodoItem;
