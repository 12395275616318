import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import womenB from 'assets/images/Ellipse 22.jpg';
import { ReactComponent as Embassy } from 'assets/svg/embassy.svg';
import { ReactComponent as Visualization } from 'assets/svg/visualization_skill.svg';
import { ReactComponent as Laptop } from 'assets/svg/laptop.svg';
import { ReactComponent as Hand } from 'assets/svg/hand.svg';
export interface SectionCProps {}

const SectionC: React.FC<SectionCProps> = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const div = useRef<any>();
  const scrollCallBack = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setVisible(!visible);
    }
  };
  const visibleCircle = new IntersectionObserver(scrollCallBack);

  useEffect(() => {
    visibleCircle.observe(div.current);

    return () => {
      visibleCircle.disconnect();
    };
  }, []);

  return (
    <div className="bg-yellow-150  py-10 lg:pt-10 pt-32 relative border-t border-yellow-150 lg:px-16  w-full flex-col">
      <div ref={div} className="lg:grid lg:mt-32  lg:grid-cols-9">
        <div className="lg:col-span-4 md:flex w-full md:-mb-40 lg:mb-0 justify-center items-center lg:block ">
          <div className=" md:h-257 md:w-618 relative  lg:col-span-3 ">
            <div
              className={` absolute  md:left-22 md:top-90 transition-all duration-700 transform ${
                visible
                  ? 'opacity-100 translate-x-0'
                  : 'opacity-0 -translate-x-150%'
              } z-10 h-333 w-333 -left-10 -top-115 rounded-full bg-yellow-130 `}
            ></div>
            <div
              className={`absolute transition-all duration-700 transform ${
                visible
                  ? 'opacity-100 translate-x-0'
                  : 'opacity-0 -translate-x-150%'
              } md:-left-115 md:-top-241 z-0 bottom-0 h-333 w-333 -top-365  rounded-full bg-orange-350 `}
            ></div>
            <div
              className={` md:-top-131 md:right-149 absolute bottom-0 transition-all duration-700 transform ${
                visible
                  ? 'opacity-100 translate-x-0'
                  : 'opacity-0 -translate-x-150%'
              } h-110 w-110 z-20 right-32 -top-291 rounded-full bg-yellow-160 `}
            ></div>
            <img
              className={`absolute transition-all duration-700 transform ${
                visible
                  ? 'opacity-100 translate-x-0'
                  : 'opacity-0 -translate-x-150%'
              }  md:right-170 md:-top-63 sm-right-5-top-177 h-333 w-333 z-10 bottom-0 rounded-full `}
              src={womenB}
              alt="..."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-5 ${
            visible ? 'translate-x-0 opacity-100' : 'translate-x-150% opacity-0'
          } transform  lg:pt-0 transition-all duration-700  pt-64 lg:mt-0 mt-56`}
        >
          <div className="text-black lg:text-left lg:px-0 px-2 text-center relative z-20  lg:text-4xl text-36 lg:whitespace-no-wrap font-bold py-3">
            {t('Offboarding & more')}
          </div>
          <div className="lg:w-4/5 lg:px-0 px-3 text-black text-2xl">
            {t('title of section c')}
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 md:pt-48 pt-32">
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-white transition-all duration-700 transform hover:rotate-180 lg:w-224 lg:h-224 h-137 w-137 rounded-full flex justify-center items-center">
              <Embassy className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black whitespace-no-wrap w-full text-center  lg:text-3xl py-6 text-18  font-bold ">
              Verhoog
              <br /> ambassadeurschap
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-green-450 transition-all duration-700 transform hover:rotate-180 lg:w-224 lg:h-224 h-137 w-137 rounded-full flex justify-center items-center">
              <Visualization className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black whitespace-no-wrap w-full text-center  lg:text-3xl py-6 text-18  font-bold ">
              Verkrijg cruciale
              <br /> inzichten
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-white transition-all duration-700 transform hover:rotate-180 rounded-full lg:w-224 lg:h-224 h-137 w-137 flex justify-center items-center">
              <Laptop className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black w-full whitespace-no-wrap text-center  lg:text-3xl py-6 text-18  font-bold ">
              Krijg jouw <br />
              hardware terug
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className=" transition-all duration-700 transform hover:rotate-180 bg-green-450 lg:w-224 lg:h-224 h-137 w-137 rounded-full flex justify-center items-center">
              <Hand className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black whitespace-no-wrap w-full text-center  lg:text-3xl py-6 text-18  font-bold ">
              Neem prettig
              <br /> afscheid en meer..
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionC;
