import React from 'react';
export interface ProgressProps {
  percet: string | number;
  title?: string;
  borderColor?: string;
}

const Progress: React.FC<ProgressProps> = ({
  percet = 80,
  title = 'Completion rate',
  borderColor = 'black',
}) => {
  return (
    <div className="whitespace-no-wrap  flex flex-col text-12  sm:text-14 md:text-base">
      <div className="py-1 text-10 flex w-full justify-between">
        <div className="mr-5">{title}</div>
        <div>{percet}%</div>
      </div>
      <div className="w-full">
        <div
          style={{ border: `1px solid ${borderColor}` }}
          className=" rounded-full  p-1-px"
        >
          <div
            style={{ width: `${percet}%`, backgroundColor: borderColor }}
            className={` h-2 rounded-full`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
