import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { Input, Layout, Spinner } from 'components';

import gate from 'gate';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { RequestchangePassword } from 'types/api/auth';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    password: yup.string().required(t('field is required')),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('the passwords do not match')),
  });
  const location = useLocation();
  const router = useHistory();
  const token = new URLSearchParams(location.search).get('token');

  const { errors, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });
  const _postData = async (data: RequestchangePassword) =>
    await gate.changePassword(data);
  const { data, error, isLoading, mutate: postData } = useMutation(_postData);

  useEffect(() => {
    if (data) {
      toast.success(data?.message);
      return router.replace('/');
    }
    if (error) {
      toast.error((error as any)?.data?.message || 'Unexpcted error!');
    }
  }, [data, error]);

  const onSubmit = (data: RequestchangePassword) => {
    postData({ password: data.password, token: token ?? '' });
  };

  return (
    <Layout className=" min-h-screen justify-end items-end bg-yellow-150">
      <div className="w-full  pb-32 flex justify-center items-center">
        <Logo />
      </div>
      <div className="w-full flex flex-col justify-center items-center ">
        <div className="w-full text-center  pb-24 font-bold text-xl">
          {t('Set new password')}
        </div>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full justify-center pb-2 px-3">
            <Controller
              as={Input}
              type="password"
              title={t('New password')}
              name="password"
              labelClassName="pb-1"
              error={((errors?.password as unknown) as FieldError)?.message}
              placeholder="Fill in your company mail"
              className="w-full px-6"
              control={control}
              inputClassName="w-full rounded-full border-none "
            />
          </div>
          <div className="flex w-full justify-center px-3">
            <Controller
              as={Input}
              type="password"
              title={t('Retype new password')}
              name="repeatPassword"
              labelClassName="pb-1"
              error={
                ((errors?.repeatPassword as unknown) as FieldError)?.message
              }
              placeholder="Fill in your company mail"
              className="w-full px-6"
              control={control}
              inputClassName="w-full rounded-full border-none "
            />
          </div>

          <div className="flex pb-24 w-full justify-center items-center p-8">
            <button
              type="submit"
              className="rounded-full uppercase text-white bg-black px-12 py-4  "
            >
              {isLoading ? <Spinner className="w-6 h-6" /> : 'Reset'}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ChangePassword;
