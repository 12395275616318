import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getContrastYIQ } from 'utils';

import {
  Button,
  FeedItem,
  Card,
  HorizontalList,
  LoadingPage,
  LoadingDots,
  Feedback,
} from 'components';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';
import { ReactComponent as SendIcon } from 'assets/svg/sendIcon.svg';
import { useMutation, useQueries } from 'react-query';
import gate from 'gate';
import { capitalize } from 'lodash';
import { Colleague, RequestMe, RequestTip } from 'types/api/user';
import { toast } from 'react-toastify';
import { useTheme } from 'styles/theme';
import { FeedbackTypes } from 'types/api/default';

const Profile: React.FC = () => {
  const [text, setText] = useState<string>('');
  const { t } = useTranslation();
  const { mainColor } = useTheme();
  const { mutate, isLoading: tipLoading } = useMutation(
    async (data: RequestTip) => {
      try {
        await gate.postTip(data);

        toast.success('Thank you for sending tip.');
        setText('');
      } catch (err) {
        console.log('err:', err);
      }
    },
  );
  const results = useQueries([
    { queryKey: ['me', 1], queryFn: async () => await gate.me() },
    {
      queryKey: ['colleagues', 2],
      queryFn: async () => await gate.colleagues(),
    },
    {
      queryKey: ['feedback', 3],
      queryFn: async () => await gate.feedback(),
    },
  ]);
  const [
    { isLoading, data: meData },
    { data: colleagues, isLoading: colleaguesLoading },
    { data: feedback, isLoading: feedbackLoading },
  ] = results;
  const date =
    new Date((meData as any)?.startTime)?.getTime() - new Date().getTime();
  const startTime = Math.round(date / 86400000) ?? -1;
  const [feedBack, setFeedBack] = useState<number>(0);
  const handleSubmit = async () => {
    mutate({ content: text });
  };

  const color = getContrastYIQ(mainColor) === 'black' ? 'white' : 'black';
  const colorText = getContrastYIQ(mainColor) === 'black' ? 'black' : 'white';

  if (isLoading) {
    return <LoadingPage />;
  }

  const filteredFeedbacks = (feedback as FeedbackTypes[])?.filter(
    (feed: FeedbackTypes) => feed?.userFeedbackRates?.length === 0,
  );
  const { allItem, completeItem } = (meData as RequestMe)?.progress ?? {};
  const progress =
    Math.round((Number(completeItem) / Number(allItem)) * 100) || 0;
  const showFeedback =
    feedBack < (filteredFeedbacks as unknown[])?.length &&
    (feedback as unknown[])?.length;
  return (
    <div className=" animate-fadeLeft min-h-screen flex flex-col">
      <div className="flex flex-col pt-10 justify-center items-center">
        <div className="w-full flex justify-center items-center">
          <div className="mr-2 flex justify-center items-center ">
            <img
              className="w-90 h-90 object-cover rounded-full"
              src={(meData as any)?.avatar ?? avatarPlaceholder}
              alt="..."
            />
          </div>
          <div className="">
            <div className=" w-full   text-21 font-bold text-left ">
              {capitalize((meData as any)?.firstName) +
                ' ' +
                capitalize((meData as any)?.lastName)}
            </div>
            <div className=" text-14 pt-1 font-bold">
              {(meData as any)?.function}
            </div>
            <div className=" p-2 ">
              <Link to="/settings">
                <button
                  style={{
                    backgroundColor: mainColor,
                    color: getContrastYIQ(mainColor),
                  }}
                  className={` rounded-full text-14  px-6 py-1`}
                >
                  {t('Settings')}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 pt-8  ">
          <div className=" p-3">
            <div className="bg-white flex justify-center items-center min-w-162  h-40 rounded-lg shadow-sm">
              <div className={`c100 p${progress}`}>
                <span className="font-semibold">{progress}%</span>
                <div className="slice">
                  <div className="bar"></div>
                  <div className="fill"></div>
                </div>
              </div>
            </div>
          </div>
          <div className=" p-3">
            <div className="bg-white shadow-sm min-w-162   h-40 flex flex-col justify-center items-center  rounded-lg">
              <div className="text-3xl font-bold text-black">
                {startTime >= 0 ? startTime : 0}
              </div>
              <div className="w-1/2 text-10 text-black">
                dagen tot uw eerste werkdag
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            showFeedback ? 'h-auto' : 'h-0'
          } overflow-hidden w-full transition-all duration-300 `}
        >
          <div className=" text-24 w-full text-left font-bold pt-4 pl-3">
            {t('Get feedback')}
          </div>
          <div className="w-full flex overflow-x-scroll justify-center items-center scrollbar-none py-4 px-4">
            {feedbackLoading ? (
              <LoadingDots />
            ) : (
              (filteredFeedbacks as FeedbackTypes[])?.map(
                ({ feedback, id, userFeedbackRates }: any, index) => {
                  if (index === feedBack) {
                    return (
                      <FeedItem
                        key={id}
                        id={id}
                        rate={userFeedbackRates[0]?.rate ?? 0}
                        index={index}
                        setFeedBack={setFeedBack}
                        title={feedback}
                      />
                    );
                  }
                },
              )
            )}
          </div>
        </div>

        <div className=" text-24 w-full text-left font-bold pt-4 pl-3">
          {t('Meet your colleagues')}
        </div>
        <div className=" w-full flex py-3 overflow-hidden">
          {colleaguesLoading ? (
            <LoadingDots />
          ) : (
            <HorizontalList
              data={colleagues as Colleague[]}
              renderItem={(data) => (
                <div className="mx-2 my-2 ">
                  <Card data={data} />
                </div>
              )}
            />
          )}
        </div>
        <div className=" text-24 w-full text-left font-bold pt-1 pl-3">
          {t('Tips & Suggestions')}
        </div>
        <div className="p-2 pb-6 px-3">
          <div className="bg-white p-3 flex flex-col rounded-lg shadow-sm">
            <div className="text-black my-2 text-lg font-bold ">
              {t('We would love to hear from you how weimprove the app!')}
            </div>
            <div className="flex justify-end items-end pb-1 ">
              <textarea
                onChange={(e) => setText(e.target.value)}
                value={text}
                className="border p-2 border-gray-150 text-black rounded-lg bg-white focus:outline-none w-11/12 h-84"
              ></textarea>

              <Button
                style={{ backgroundColor: mainColor }}
                isLoading={tipLoading}
                onClick={handleSubmit}
                className="w-8 mb-1 h-8 cursor-pointer ml-2   flex justify-center items-center rounded-full"
              >
                <SendIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
