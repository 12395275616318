import React, { ReactNode } from 'react';
import Footer from './Footer';
import { useTheme } from 'styles/theme';

export interface WebAppLayoutProps {
  children: ReactNode;
  className?: string;
  backgroundColor?: string;
  footer?: boolean;
}

const WebAppLayout: React.FC<WebAppLayoutProps> = ({
  children,
  className,
  footer,
  backgroundColor: bgColor,
}) => {
  const { backgroundColor, mainColor } = useTheme();
  return (
    <div
      style={{ color: mainColor }}
      className="lg:w-full relative lg:flex lg:justify-center lg:items-center"
    >
      <main
        style={{ backgroundColor: bgColor ?? backgroundColor }}
        className={`flex flex-col overflow-x-hidden relative ${
          footer && 'pb-24'
        } lg:min-w-600 min-h-screen max-w-600 ${className}`}
      >
        {children}
        {footer && <Footer />}
      </main>
    </div>
  );
};

export default WebAppLayout;
