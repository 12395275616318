import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import women from 'assets/images/pexels-jorge-fakhouri-filho-2701660-removebg-preview 1.png';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex lg:px-16 px-4  bg-yellow-150 overflow-hidden p-3 flex-col w-full justify-center items-center ">
      <div className="flex mt-2 mb-20 md:mt-0 md:mb-0  w-full  justify-between">
        <div className="flex lg:my-0   justify-center items-center">
          <Logo className="h-6" />
        </div>
        <div className="justify-center items-center">
          <a href={process.env.REACT_APP_HEADSTSRT_APP_URL}>
            <button className="bg-black text-base px-5 lg:px-8 rounded-full text-white cursor-pointer  py-2 flex justify-center items-center lg:text-3xl ">
              {t('login')}
            </button>
          </a>
        </div>
      </div>

      <div className="grid lg:grid-cols-8 w-full justify-end items-end  lg:mt-40 ">
        <div className="  lg:col-span-5 w-full lg:px-5 flex flex-col justify-center">
          <div className="text-black  lg:whitespace-no-wrap py-3 font-semibold w-full  text-36 lg:text-5xl">
            {t('Making First Days , Great Days')}
          </div>
          <div className="flex z-10 justify-center lg:w-4/5 w-full items-center text-24 lg:text-3xl text-black">
            {t('title of header')}
          </div>
          <div className=" hidden  py-6 lg:flex justify-start items-center">
            <a
              href="#contactformulier"
              className=" py-3 px-8 border-b-4 shadow-xl  border-black rounded-full  bg-green-450 text-black text-2xl "
            >
              {t('Please feel free to contact us')}
            </a>
          </div>
        </div>
        <div className="md:flex lg:block block justify-center items-center">
          <div className="  h-384 md:h-342 md:w-473 lg:mt-0  relative  lg:col-span-3 mr-12 ">
            <div className=" absolute h-333 w-333 md:-left-72 bottom-0  -ml-5 -mb-5  z-0  rounded-full bg-yellow-130 "></div>
            <div className=" absolute top-0 h-247 w-247 md:-top-89 md:right-9  -right-130 z-0  rounded-full bg-orange-350  "></div>
            <div className=" absolute bottom-0 h-110 w-110 md:right-0  -right-130 z-0  rounded-full bg-yellow-160  "></div>
            <img
              className="absolute  -bottom-16 left-99 "
              src={women}
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
