import React from 'react';

export interface ChatSpinnerProps {}

const ChatSpinner: React.SFC<ChatSpinnerProps> = () => {
  return (
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default ChatSpinner;
