import api from './api';
import user from './user';
import company from './company';
const snedMail: any = {
  sendMail: (data: any) => api.post('/staff/send-email', data),
};
const uploadFile = {
  uploadFile: (
    data: FormData,
    onUploadProgress: ((progressEvent: any) => void) | undefined,
  ) => api.file('/storage/upload', data, onUploadProgress),
};

export default {
  ...uploadFile,
  ...user,
  ...snedMail,
  ...company,
};
