export const getContrastYIQ = (color: string) => {
  let hexcolor = color ? color : '';
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};
export const getDownloadAddress = (fileName: string) => {
  // console.log(
  //   '%c fileName:',
  //   'color:green ;background:white;padding:12px',
  //   fileName,
  // );

  if (fileName.includes('http') || fileName.includes('/static'))
    return fileName;
  return `${process.env.REACT_APP_BASE_URL}storage/download/${fileName}`;
};
