import React, { FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import gate from 'gate';

import {
  GalleryWidget,
  LoadingPage,
  Media,
  PDFWidget,
  QuizWidget,
  TextWidget,
  TodoWidget,
} from 'components';
import { getDownloadAddress } from 'utils';

type routeParam = {
  id: string;
};
const WidgetPage: FC = () => {
  const { id } = useParams<routeParam>();
  const location = useLocation();
  const completed = new URLSearchParams(location.search).get('completed');
  const type = new URLSearchParams(location.search).get('type');

  const cache = useQueryClient();
  const { mutate: completeWidget } = useMutation(async () => {
    try {
      await gate.completeWidget(Number(id));
      cache.invalidateQueries('home');
    } catch (error) {}
  });
  useEffect(() => {
    if (completed === 'false' && type !== 'Quiz' && type !== 'ToDo') {
      completeWidget();
    }
  }, []);
  const { data, isLoading } = useQuery(
    `widget/${id}`,
    async () => await gate.widget(Number(id)),
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  switch (data.type) {
    case 'ToDo':
      return <TodoWidget data={data} />;
    case 'Pdf':
      return <PDFWidget url={getDownloadAddress(data?.content?.filename)} />;
    case 'Quiz':
      return <QuizWidget data={data} />;
    case 'Media':
      return <Media data={data} />;
    case 'Audio':
      return <Media data={data} />;
    case 'Text':
      return <TextWidget data={data} />;
    case 'Gallery':
      return <GalleryWidget data={data} />;

    default:
      return null;
  }
};
export default WidgetPage;
