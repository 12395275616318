import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';

import gate from 'gate';
import { Button } from 'components';
import { useTheme } from 'styles/theme';

interface Props {
  file: any;
  setResponse: (data: any) => any;
}
const CropperCompenent: React.FC<Props> = ({ file, setResponse }) => {
  const { mainColor } = useTheme();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [image, setImage] = useState();
  const [visible, setVisible] = useState<boolean>(false);
  const [cropData, setCropData] = useState<any>('#');
  const [cropper, setCropper] = useState<any>();
  const onReciveFile = (e: any) => {
    if (!e) return;
    setVisible(true);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    onReciveFile(file);
  }, [file]);

  function dataURItoBlob(dataURI: any) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      let image = cropper.getCroppedCanvas().toDataURL('image/jpeg', 20 / 100);
      const img = dataURItoBlob(image);
      setCropData(img);
    }
  };
  const uploadFile = async (formData: FormData) => {
    setIsLoading(true);
    try {
      const res = await gate.uploadFile(formData, () => {});

      setResponse(res);
    } catch (err) {
      toast.error(err?.data?.message || 'An Unexpected Error Occurred');
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  };
  useEffect(() => {
    if (cropData !== '#') {
      const formData: FormData = new FormData();
      formData.append('file', cropData);
      uploadFile(formData);
    }
  }, [cropData]);
  return (
    <div>
      <div>
        {visible && (
          <div
            onClick={() => {
              setVisible(false);
            }}
            className="bg-black opacity-25 right-0 left-0 top-0 bottom-0 z-20 w-screen h-screen fixed"
          ></div>
        )}
        <div
          className={` w-full absolute left-0  top-0  flex justify-center items-center ${
            visible && 'h-screen'
          }`}
        >
          <div
            className={` transform transition-all  duration-700 ${
              !visible
                ? '-translate-y-20 opacity-0'
                : 'opacity-100 translate-x-0'
            }  fixed z-50`}
          >
            <div className="lg:w-1/2 relative ">
              <Cropper
                className="h-80 w-full"
                // style={{ width: '100%', height: 400 }}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                responsive
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />

              <div className=" flex justify-center items-center p-4 absolute bottom-0 -mt-10">
                <button className="w-8 text-xs mx-4 h-8 flex justify-center items-center text-white border bg-transparent border-black">
                  1:1
                </button>
              </div>
            </div>

            <div className="w-full bg-transparent p-4 flex justify-center items-center">
              <Button
                isLoading={isLoading}
                style={{ backgroundColor: mainColor }}
                className="  font-bold text-white rounded-lg px-5 py-2"
                onClick={() => {
                  getCropData();
                }}
              >
                {t('upload')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropperCompenent;
