import React from 'react';
export interface FaqItemProps {
  question: string;
  showContent: boolean;
  onShowContent: VoidFunction;
  answer: string;
}

const FaqItem: React.FC<FaqItemProps> = ({
  answer,
  onShowContent,
  question,
  showContent,
}) => {
  return (
    <div className="flex flex-col  duration-300 transition-all px-4  py-2">
      <div className="flex  w-full relative">
        <div
          onClick={onShowContent}
          className="text-black cursor-pointer whitespace-normal  overflow-hidden w-11/12 text-14"
        >
          {question}
        </div>
        <div
          onClick={onShowContent}
          className="cursor-pointer text-black w-1/12 flex h-full justify-center items-center text-4xl  right-0 absolute"
        >
          {showContent ? '-' : '+'}
        </div>
      </div>
      <div
        className={`  text-black opacity-50 text-xs overflow-hidden  transform transition-all duration-300 ease-linear ${
          showContent ? ' ' : ' h-0 '
        } text-14 `}
      >
        {answer}
      </div>
    </div>
  );
};

export default FaqItem;
