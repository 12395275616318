import React from 'react';
export interface CryIconProps {
  active?: boolean;
}

const CryIcon: React.FC<CryIconProps> = ({ active }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? '1' : '0.195359'}>
        <path
          d="M20.84 9.44L19.16 10.56C19.16 10.56 18.91 10.13 18.47 9.72C18.03 9.31 17.45 9 17 9H14V7H17C18.24 7 19.18 7.69 19.81 8.28C20.45 8.87 20.84 9.44 20.84 9.44Z"
          fill="black"
        />
        <path
          d="M12 7V9H8.99997C8.54997 9 7.96997 9.31 7.52997 9.72C7.08997 10.13 6.83997 10.56 6.83997 10.56L5.15997 9.44C5.15997 9.44 5.54997 8.87 6.18997 8.28C6.81997 7.69 7.75997 7 8.99997 7H12Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 16.5C10 18.43 11.346 20 13 20C14.654 20 16 18.43 16 16.5C16 14.57 14.654 13 13 13C11.346 13 10 14.57 10 16.5ZM12 16.5C12 15.701 12.468 15 13 15C13.532 15 14 15.701 14 16.5C14 17.299 13.532 18 13 18C12.468 18 12 17.299 12 16.5Z"
          fill="black"
        />
        <path
          d="M19 24C18.447 24 18 23.552 18 23V13C18 12.448 18.447 12 19 12C19.553 12 20 12.448 20 13V23C20 23.552 19.553 24 19 24Z"
          fill="black"
        />
        <path
          d="M7 24C6.447 24 6 23.552 6 23V13C6 12.448 6.447 12 7 12C7.553 12 8 12.448 8 13V23C8 23.552 7.553 24 7 24Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13C0 20.168 5.832 26 13 26C20.168 26 26 20.168 26 13C26 5.832 20.168 0 13 0C5.832 0 0 5.832 0 13ZM2 13C2 6.935 6.935 2 13 2C19.065 2 24 6.935 24 13C24 19.065 19.065 24 13 24C6.935 24 2 19.065 2 13Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export default CryIcon;
