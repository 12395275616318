import React from 'react';
export interface NeutralIconProps {
  active?: boolean;
}

const NeutralIcon: React.FC<NeutralIconProps> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? '1' : '0.195359'}>
        <path
          d="M12 0C5.37258 0 0 5.37258 0 12H2C2 6.47715 6.47715 2 12 2V0ZM0 12C0 18.6274 5.37258 24 12 24V22C6.47715 22 2 17.5228 2 12H0ZM12 24C18.6274 24 24 18.6274 24 12H22C22 17.5228 17.5228 22 12 22V24ZM24 12C24 5.37258 18.6274 0 12 0V2C17.5228 2 22 6.47715 22 12H24ZM7 18H17V16H7V18Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 9.5C6 8.67157 6.67157 8 7.5 8C8.32843 8 9 8.67157 9 9.5C9 10.3284 8.32843 11 7.5 11C6.67157 11 6 10.3284 6 9.5ZM15 9.5C15 8.67157 15.6716 8 16.5 8C17.3284 8 18 8.67157 18 9.5C18 10.3284 17.3284 11 16.5 11C15.6716 11 15 10.3284 15 9.5Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export default NeutralIcon;
