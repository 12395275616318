import React, { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import gate from 'gate';
import { useTheme } from 'styles/theme';

import { FaqItem, LoadingPage } from 'components';
import { getContrastYIQ } from 'utils';

const FAQ: React.FC = () => {
  type obj = {
    key: number;
    visible: boolean;
  };
  const { data: faqData, isLoading } = useQuery(
    'faq',
    async () => await gate.faq(),
  );
  const { mainColor, backgroundColor } = useTheme();
  const [{ key, visible }, setVisible] = useState<obj>({
    key: 0,
    visible: false,
  });
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const temp = faqData;

    if (temp) {
      temp.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));

      setData(temp);
    }
  }, [isLoading]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    const data_ = faqData.filter(
      (d: any) =>
        d.answer.toLowerCase().includes(text.toLowerCase()) ||
        d.question.toLowerCase().includes(text.toLowerCase()),
    );

    setData(data_);
    if (text === '') {
      setData(faqData);
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="animate-opacity flex w-full flex-col">
      <div className="w-full pt-8 text-4xl font-bold  flex justify-center items-center text-center">
        FAQ
      </div>
      <div className="w-full pt-6 flex justify-center">
        <input
          onChange={(e) => handleChange(e)}
          className="bg-white focus:outline-none w-9/12 rounded-full px-3 py-2"
          placeholder="Search"
          style={{ borderWidth: 1, borderColor: mainColor }}
        />
      </div>
      {!!data?.length && (
        <div className="flex w-full px-5 pt-10 justify-center items-center">
          <div
            className="w-full py-3 divide-y divide-white rounded-2xl"
            style={{
              backgroundColor:
                getContrastYIQ(backgroundColor) === 'black'
                  ? '#edf2f7'
                  : '#ffffff61',
            }}
          >
            {data?.map(({ id, question, answer }: any) => {
              return (
                <FaqItem
                  key={id}
                  answer={answer}
                  question={question}
                  showContent={visible && key === id}
                  onShowContent={() =>
                    setVisible((prev) => {
                      return {
                        key: id,
                        visible: !prev.visible,
                      };
                    })
                  }
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQ;
