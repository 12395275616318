import {
  RequestchangePassword,
  RequestForgetPassword,
  RequestLogin,
  RequestRegister,
} from 'types/api/auth';
import { FeedbackTypes } from 'types/api/default';
import {
  Colleague,
  RequestEditMe,
  RequestFeedbackRate,
  RequestMe,
  RequestResetPassword,
  RequestTip,
} from 'types/api/user';
import api from './api';
export default {
  changePassword: (data: RequestchangePassword) =>
    api.post('/company/forget-password', data),
  forgetPassworfRequest: (data: RequestForgetPassword) =>
    api.post('/staff/forget-password-request', data),
  register: (data: RequestRegister) => api.post(`/staff/register`, data),
  me: () => api.get<RequestMe>('/staff/me'),
  editMe: (data: RequestEditMe) => api.post('/staff/edit-me', data),
  login: (data: RequestLogin) => api.post(`/staff/login`, data),
  colleagues: () => api.get<Colleague[]>('/staff/colleague'),
  postTip: (data: RequestTip) => api.post('/staff/tip', data),
  feedback: () => api.get<FeedbackTypes[]>('/staff/feedback'),
  resetPassword: (data: RequestResetPassword) =>
    api.post('/staff/change-password', data),
  feedbackRate: ({ feedback, rate }: RequestFeedbackRate) =>
    api.post(`/staff/feedback/${feedback}/rate`, { rate }),
};
