import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CriticalThinking } from 'assets/svg/critical_thinking.svg';
import { ReactComponent as Document } from 'assets/svg/document.svg';
import { ReactComponent as Mask } from 'assets/svg/protection_mask.svg';
import { ReactComponent as Protect } from 'assets/svg/protect.svg';
import men from 'assets/images/Ellipse 12.jpg';

const SectionB: React.FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const div = useRef<any>();
  const scrollCallBack = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setVisible(!visible);
    }
  };
  const visibleCircle = new IntersectionObserver(scrollCallBack);

  useEffect(() => {
    visibleCircle.observe(div.current);

    return () => {
      visibleCircle.disconnect();
    };
  }, []);
  return (
    <div className="bg-yellow-150  lg:py-32 md:pt-16 pt-96  lg:pt-32 overflow-x-hidden border-2 border-yellow-150  w-full flex-col">
      <div
        ref={div}
        className="lg:grid flex flex-col-reverse mt-32 lg:grid-cols-9"
      >
        <div
          className={`lg:col-span-5  lg:pl-24 xl:pl-32 transform transition-all duration-700 ${
            visible
              ? 'opacity-100 translate-x-0'
              : 'opacity-0 -translate-x-150%'
          } `}
        >
          <div className="text-black lg:text-4xl   text-36 w-full xl:text-left text-center lg:text-left lg:whitespace-no-wrap font-bold py-3">
            {t('Reboard your employees')}
          </div>
          <div className="lg:w-4/5 px-3 lg:px-0 text-black text-2xl">
            {t('title of section b')}
          </div>
        </div>

        <div className="md:w-full md:justify-center md:items-center md:pb-20 md:flex lg:block">
          <div className="lg:col-span-4 lg:w-618 xl:w-618 lg:h-293 xl:h-293 md:h-293 md:w-618">
            <div className=" relative h-full  lg:col-span-3 ">
              <div
                className={` absolute   z-20 h-333 lg:-top-145 w-333 md:-left-87 md:-top-145 xl:-left-87 lg:-left-87 -top-519 -left-210 transition-all duration-700 transform ${
                  visible
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-150%'
                }  rounded-full bg-yellow-130 `}
              ></div>
              <div
                className={`absolute transition-all duration-700 transform ${
                  visible
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-150%'
                } lg:right-5 md:right-5 xl:right-5 md:-bottom-33 lg:-bottom-33 xl:-bottom-33 h-247 w-247 bottom-3 -right-87  z-0   rounded-full bg-orange-350  `}
              ></div>
              <div
                className={` absolute h-110 w-110 lg:left-140 xl:left-140 md:left-140 md:-bottom-89 lg:-bottom-89 -left-14 -bottom-33   z-20  rounded-full bg-yellow-160 transition-all duration-700 transform ${
                  visible
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-150%'
                } `}
              ></div>
              <img
                className={`absolute transition-all duration-700 transform ${
                  visible
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-150%'
                } lg:-top-58 xl:-top-58 md:-top-58 md:right-140 lg:right-140 xl:right-140  -top-382 right-22 h-350 w-350 z-10 bottom-0 rounded-full `}
                src={men}
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 md:pb-40 lg:pb-0 lg:px-16 pt-32">
        <div className="p-3 ">
          <div className="flex  flex-col justify-center items-center">
            <div className="bg-green-450 transition-all duration-700 transform hover:rotate-180 lg:h-224 lg:w-224 w-137 h-137 rounded-full flex justify-center items-center">
              <CriticalThinking className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black whitespace-no-wrap w-full text-center  lg:text-3xl py-6 text-18 font-bold ">
              Verhoog <br /> bewustwording
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex  flex-col justify-center items-center">
            <div className="bg-white transition-all duration-700 transform hover:rotate-180 lg:h-224 lg:w-224 w-137 h-137 rounded-full flex justify-center items-center">
              <Document className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black whitespace-no-wrap w-full text-center  lg:text-3xl py-6 text-18 font-bold ">
              Deel jouw
              <br /> protocollen
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex  flex-col justify-center items-center">
            <div className="bg-green-450 transition-all duration-700 transform hover:rotate-180 lg:h-224 lg:w-224 w-137 h-137 rounded-full flex justify-center items-center">
              <Mask className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black w-full whitespace-no-wrap text-center  lg:text-3xl py-6 text-18 font-bold ">
              Voorkom <br /> besmettingen
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex  flex-col justify-center items-center">
            <div className="bg-white transition-all duration-700 transform hover:rotate-180 lg:h-224 lg:w-224 w-137 h-137 rounded-full flex justify-center items-center">
              <Protect className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black w-full whitespace-no-wrap text-center  lg:text-3xl py-6 lg:mr-0 xl:mr-0 md:mr-0 mr-8 text-18 font-bold ">
              <div className="lg:mr-0 xl:mr-0  -mr-8">Zorg voor</div>
              een veilige werkplek
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionB;
