import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Leadership } from 'assets/svg/leadership.svg';
import { ReactComponent as Friends } from 'assets/svg/friends.svg';
import { ReactComponent as Company } from 'assets/svg/company.svg';
import { ReactComponent as Suit } from 'assets/svg/suit.svg';
import womenA from 'assets/images/Ellipse 1.jpg';
export interface SectionAProps {}

const SectionA: React.FC<SectionAProps> = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const circle = useRef<any>();
  const scrollCallBack = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      setVisible(!visible);
    }
  };
  const visibleCircle = new IntersectionObserver(scrollCallBack);

  useEffect(() => {
    visibleCircle.observe(circle.current);

    return () => {
      visibleCircle.disconnect();
    };
  }, []);

  return (
    <div className="bg-yellow-150 overflow-x-hidden border-b  border-yellow-150 lg:py-10 md:pt-32 pt-56 lg:pb-0 lg:pt-0 lg:px-8 xl:px-16  w-full flex-col">
      <div className="grid lg:mt-32 lg:grid-cols-9">
        <div className="lg:col-span-4 md:flex justify-center md:-mb-8 lg:mb-0 items-center w-full block">
          <div className=" relative h-full w-auto md:w-618  md:h-329 lg:col-span-3 ">
            <div
              ref={circle}
              className={`absolute   transition-all duration-1000 md:top-55 transform  ${
                visible ? 'translate-x-0' : ' -translate-x-full'
              }  z-10 h-333 w-333 -top-89  rounded-full bg-yellow-130`}
            ></div>
            <div
              className={`transition-all duration-1000  ease-in-out absolute h-247 transform  ${
                visible
                  ? 'translate-x-0 opacity-100'
                  : 'opacity-0 -translate-x-full'
              } md:right-170 xl:-top-82 md:-top-82   w-247 z-0 bottom-0 right-0 -top-190   rounded-full bg-orange-350  `}
            ></div>
            <div
              className={` absolute transition-all  duration-500 ease-out transform ${
                visible ? 'translate-x-0' : '-translate-x-150%'
              }   duration-700 bottom-0 md:-top-52 md:left-7 -top-131 -left-18  h-110 w-110  z-20  rounded-full bg-yellow-160`}
            ></div>
            <img
              className={`absolute md:top-0 transform transition-all duration-1000 ${
                visible
                  ? 'translate-x-0 opacity-100'
                  : 'opacity-0 -translate-x-150%'
              } md:left-0 -left-14 -top-169 z-10 bottom-0 h-350 w-350 rounded-full `}
              src={womenA}
              alt="..."
            />
          </div>
        </div>
        <div
          className={`lg:col-span-5 transition-all duration-700 transform ${
            visible ? 'translate-x-0 opacity-100' : 'opacity-0 translate-x-150%'
          } lg:w-4/5 mt-64 lg:mt-0 `}
        >
          <div className="text-black  lg:text-4xl text-36 w-full lg:text-left xl:text-left text-center lg:whitespace-no-wrap font-bold py-3">
            {t('Pre- & Onboard your employees')}
          </div>
          <div className="lg:w-4/5 w-full p-3 lg:p-0  text-black lg:text-2xl text-24">
            {t('title of section a')}{' '}
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 pt-20">
        <div className="p-3 ">
          <div className="flex flex-col  justify-center items-center">
            <div className="bg-white transition-all duration-700 transform hover:-rotate-180 lg:h-224 lg:w-224 h-137 w-137 rounded-full flex justify-center items-center">
              <Leadership className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black w-full text-center whitespace-no-wrap lg:text-3xl py-6 text-18  font-bold ">
              Verhoog de <br /> betrokkenheid
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-green-450 transition-all duration-700 transform hover:-rotate-180 lg:h-224 lg:w-224 h-137 w-137 rounded-full flex justify-center items-center">
              <Friends className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black w-full text-center whitespace-no-wrap lg:text-3xl py-6 text-18  font-bold ">
              Maak inwerken
              <br /> persoonlijk
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-white transition-all  duration-700 transform hover:-rotate-180 lg:h-224 lg:w-224 h-137 w-137 rounded-full flex justify-center items-center">
              <Company className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black w-full text-center whitespace-no-wrap lg:text-3xl py-6 text-18  font-bold ">
              Betrek hen direct bij
              <br /> de bedrijfscultuur
            </div>
          </div>
        </div>
        <div className="p-3 ">
          <div className="flex flex-col justify-center items-center">
            <div className="bg-green-450 transition-all duration-700 transform hover:-rotate-180 lg:h-224 lg:w-224 h-137 w-137  rounded-full flex justify-center items-center">
              <Suit className="h-65 w-65 lg:h-auto lg:w-auto" />
            </div>
            <div className="text-black whitespace-no-wrap w-full text-center  lg:text-3xl py-6 text-18  font-bold ">
              Zorg voor <br />
              uniformiteit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionA;
