import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  error?: string;
  title?: string;
  labelClassName?: string;
  labelStyle?: React.CSSProperties;
  className?: string;
  inputClassName?: string;
  inputErrorClassName?: string;
  ErrorMessageClassName?: string;
}

const Input: React.FC<InputProps> = ({
  error,
  labelClassName,
  labelStyle = {},
  inputClassName,
  inputErrorClassName,
  className,
  name,
  title,
  ...rest
}) => {
  return (
    <div className={className}>
      <label
        className={`block pb-1 text-left font-bold ${labelClassName}`}
        style={labelStyle}
        htmlFor={name}
      >
        {title}
      </label>
      <input
        {...rest}
        className={`w-full p-3 ${
          error && `${inputErrorClassName ?? 'focus:border-red-600'}`
        } border ${inputClassName} `}
      />

      <p
        className={` ${
          inputErrorClassName ??
          'text-xs h-4 p-2 w-full text-center  text-red-600'
        }`}
      >
        {error && error}
      </p>
    </div>
  );
};

export default Input;
