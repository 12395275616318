import React from 'react';
import Sad from 'assets/svg/sad';
import Love from 'assets/svg/love';
import Happy from 'assets/svg/happy';
import SadCry from 'assets/svg/sad_cry';
import Neutral from 'assets/svg/neutral';
import { Button } from 'components';
import { useMutation } from 'react-query';
import gate from 'gate';
import { RequestFeedbackRate } from 'types/api/user';
export interface FeedItemProps {
  title: string;
  id: number;
  index: number;
  rate: number;
  setFeedBack: (index: number) => void;
}

const FeedItem: React.FC<FeedItemProps> = ({
  title,
  id,
  rate,
  index,
  setFeedBack,
}) => {
  const { mutate } = useMutation(async (data: RequestFeedbackRate) => {
    try {
      await gate.feedbackRate(data);
    } catch (err) {
      setFeedBack(index - 1);
    }
  });

  const onRate = (id: number, rate: number) => {
    index++;
    setFeedBack(index);
    const data: RequestFeedbackRate = { rate: rate, feedback: id };
    mutate(data);
  };

  return (
    <div className="w-full  py-3 mt-2 shadow-lg animate-fadeRight  text-black bg-white rounded-lg p-3 flex flex-col">
      <div className="w-full  pt-3 pb-6 text-lg  text-black font-bold">
        {title}
      </div>

      <div className=" w-full grid grid-cols-5">
        <Button
          onClick={() => {
            onRate(id, 1);
          }}
          className="p-2 cursor-pointer  transition-all duration-300 hover:bg-gray-200  flex justify-center items-center"
        >
          <SadCry active={rate === 1} />
        </Button>
        <Button
          onClick={() => {
            onRate(id, 2);
          }}
          className="p-2 cursor-pointer   flex justify-center items-center"
        >
          <Sad active={rate === 2} />
        </Button>
        <Button
          onClick={() => {
            onRate(id, 3);
          }}
          className="p-2 cursor-pointer   flex justify-center items-center"
        >
          <Neutral active={rate === 3} />
        </Button>
        <Button
          onClick={() => {
            onRate(id, 4);
          }}
          className="p-2 cursor-pointer   flex justify-center items-center"
        >
          <Happy active={rate === 4} />
        </Button>
        <Button
          onClick={() => {
            onRate(id, 5);
          }}
          className="p-2 cursor-pointer   flex justify-center items-center"
        >
          <Love active={rate === 5} />
        </Button>
      </div>
    </div>
  );
};

export default FeedItem;
