import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Landing, Login, WebApp } from 'pages';
import { selectUser } from 'store/selectors/user';

import ForgetPassword from 'pages/forget-password';
import ChangePassword from 'pages/change-password';
import RegisterChatBot from 'pages/web-app/register';

const Routes: React.FC = () => {
  const { loggedIn } = useSelector(selectUser);

  if (!loggedIn) {
    return (
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/:company/login" component={Login} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/:company/register" component={RegisterChatBot} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return <WebApp />;
};

export default Routes;
