import { RouteProps } from 'react-router-dom';

import FAQ from './FAQ';
import Home from './home';
import Profile from './profile';
import Settings from './settings';
import Widget from './widget';

const routes: RouteProps[] = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/faq',
    component: FAQ,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/widget/:id',
    component: Widget,
  },
];

export default routes;
