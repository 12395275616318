import React from 'react';
export interface LoadingDotsProps {
  bgClassName?: string;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({
  bgClassName = 'bg-black',
}) => {
  return (
    <div className="lds-ellipsis">
      <div className={bgClassName} />
      <div className={bgClassName} />
      <div className={bgClassName} />
      <div className={bgClassName} />
    </div>
  );
};

export default LoadingDots;
