import { Button, Slider } from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ResponseGalleryWidget } from 'types/api/widget';
import { ReactComponent as Back } from 'assets/svg/back.svg';
import { getContrastYIQ, getDownloadAddress } from 'utils';
import { useTheme } from 'styles/theme';
export interface GalleryWidgetProps {
  data: ResponseGalleryWidget;
}
const GalleryWidget: React.FC<GalleryWidgetProps> = ({ data }) => {
  const router = useHistory();
  const { mainColor, backgroundColor } = useTheme();

  return (
    <div className="w-full flex flex-col ">
      <div className="w-full pb-32 p-4">
        <Button onClick={() => router.goBack()}>
          <Back fill={mainColor} />
        </Button>
      </div>
      <Slider
        renderItem={(item, index) => (
          <div className=" p-3 relative  w-full flex flex-col justify-center items-center ">
            <img
              className="h-64 w-full object-cover  rounded-2xl "
              src={getDownloadAddress(item.filename)}
              alt={item.filename}
            />
            <div className=" text-center h-64 mt-16 z-20 w-full  ">
              {item.description}
            </div>
          </div>
        )}
        data={data.content}
      />
    </div>
  );
};

export default GalleryWidget;
