import React from 'react';
export interface HomeIconProps {
  className?: string;
  fill?: string;
}

const CustomerIcon: React.FC<HomeIconProps> = ({
  className = 'w-6 h-6',
  fill = 'white',
}) => {
  return (
    <svg
      className={className}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C6.68629 0 4 2.68629 4 6H6C6 3.79086 7.79086 2 10 2V0ZM4 6C4 9.31371 6.68629 12 10 12V10C7.79086 10 6 8.20914 6 6H4ZM10 12C13.3137 12 16 9.31371 16 6H14C14 8.20914 12.2091 10 10 10V12ZM16 6C16 2.68629 13.3137 0 10 0V2C12.2091 2 14 3.79086 14 6H16ZM2 22C2 17.5823 5.58228 14 10 14V12C4.47772 12 0 16.4777 0 22H2ZM10 14C14.4177 14 18 17.5823 18 22H20C20 16.4777 15.5223 12 10 12V14Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomerIcon;
