import React from 'react';

import { useTranslation } from 'react-i18next';
import { SectionB, Form, SectionA, SectionC, Header } from 'components';

import { ReactComponent as LowPrice } from 'assets/svg/low_price.svg';
import { ReactComponent as Brain } from 'assets/svg/brain.svg';
import { ReactComponent as LeftPhone } from 'assets/svg/leftPhone.svg';
import RightPhone from 'assets/svg/rightPhone.svg';

const Landing: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex overflow-x-hidden flex-col ">
      <Header />
      <div className="flex bg-white py-10  flex-col w-full">
        <div className="w-full  flex justify-center items-center text-3xl  lg:text-5xl font-semibold text-black">
          <div className="lg:w-full px-3 text-center w-4/5">
            {t('Choose the Headstart onboarding app')}
          </div>
        </div>
        <div className="flex py-48 relative justify-center items-center w-full ">
          <LeftPhone className="absolute w-199 lg:w-auto mr-32 lg:mr-56 z-10" />
          <img
            src={RightPhone}
            alt="..."
            className="absolute w-199 lg:w-auto ml-32 lg:ml-56 z-0"
          />
        </div>
        <div className="flex  justify-center items-center ">
          <div className="flex lg:flex-row flex-col lg:justify-between justify-center items-center ">
            <div className="flex flex-col">
              <div className="lg:mr-16 bg-yellow-150 rounded-full w-150 h-150 flex justify-center items-center">
                <Brain />
              </div>
              <div className="text-black font-bold p-4">
                {t('Most intuitive')}
              </div>
            </div>
            <div className="flex lg:ml-16 flex-col justify-center items-center">
              <div className="bg-yellow-150 w-150 h-150 rounded-full flex justify-center items-center">
                <LowPrice />
              </div>
              <div className="text-black w-full text-center  p-4 font-bold ">
                {t('Competitively priced')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SectionA />
      <SectionB />
      <SectionC />

      <div id="contactformulier" className="w-0 h-0" />
      <Form />
    </div>
  );
};

export default Landing;
