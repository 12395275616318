import * as yup from 'yup';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { Input, Layout, LoadingPage, Spinner } from 'components';

import gate from 'gate';
import { getContrastYIQ, getDownloadAddress } from 'utils';
import { login } from 'store/reducers/user';

import { ThemeProvider, useTheme } from 'styles/theme';
import { RequestLogin } from 'types/api/auth';

const WebApp: React.FC = () => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('invalid email'))
      .required(t('field is required')),
    password: yup.string().required(t('field is required')),
  });
  const router = useHistory();
  const dispatch = useDispatch();
  const { company } = useParams<any>();
  const defultTheme = useTheme();
  const {
    data: companyInfo,
    error: companyInfoError,
    isLoading: companyInfoLoading,
  } = useQuery('companyDetails', () => gate.companyInfo(company));

  const { errors, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const postData = async (data: RequestLogin) => {
    return await gate.login(data);
  };

  const { data, mutate: _login, error, isLoading } = useMutation(postData);

  const { color, logo, departments } = companyInfo ?? {};
  const mainColor =
    departments && departments?.length ? departments[0]?.mainColor : '#000000';

  const onSuccess = async () => {
    await localStorage.setItem('token', data.accessToken);
    await dispatch(
      login({
        loggedIn: true,
      }),
    );
    router.replace('/');
  };
  useEffect(() => {
    if (data) {
      onSuccess();
    }
    if (error) {
      // toast.error((error as any)?.data?.message || 'Unexpcted error!');
      toast.error((error as any)?.data?.detail?.message || 'Unexpcted error!');
    }
  }, [data, error]);

  const onSubmit = (data: RequestLogin) => {
    _login(data);
  };

  if (companyInfoLoading) {
    return <LoadingPage />;
  }
  return (
    <ThemeProvider theme={{ ...defultTheme, companyColor: color }}>
      <Layout
        backgroundColor={color}
        className=" min-h-screen justify-center items-center"
      >
        <div className="w-full animate-opacity pb-14 flex justify-center items-center mb-10">
          <img
            style={{ width: 300, maxWidth: '90%' }}
            src={getDownloadAddress(logo)}
            alt="..."
          />
        </div>
        <div className="w-full flex justify-center items-center ">
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex w-full justify-center px-3">
              <Controller
                as={Input}
                title={t('Email')}
                name="email"
                type="email"
                labelStyle={{ color: getContrastYIQ(mainColor) }}
                error={((errors?.email as unknown) as FieldError)?.message}
                placeholder={t('Fill in your company mail')}
                className="w-full px-6"
                control={control}
                inputClassName="w-full rounded-full border-none "
              />
            </div>
            <div className="flex w-full justify-center px-3 ">
              <Controller
                as={Input}
                title={t('Password')}
                name="password"
                type="password"
                labelStyle={{ color: getContrastYIQ(mainColor) }}
                error={((errors?.password as unknown) as FieldError)?.message}
                placeholder={t('Fill in your password')}
                className="w-full px-6"
                control={control}
                inputClassName="w-full  rounded-full border-none "
              />
            </div>
            <div className="flex pb-12 w-full justify-center items-center p-8">
              <button
                type="submit"
                className="rounded-full px-12 py-4"
                style={{
                  backgroundColor:
                    mainColor === color ? getContrastYIQ(mainColor) : mainColor,
                  color: mainColor,
                }}
              >
                {isLoading ? <Spinner className="w-6 h-6" /> : 'LOG IN'}
              </button>
            </div>
            <Link to="/forget-password">
              <div
                className="flex  justify-center pb-4 text-center items-center text-base"
                style={{
                  color:
                    getContrastYIQ(mainColor) === 'black' ? 'white' : 'black',
                }}
              >
                {t('Forgot your password')}
              </div>
              <div
                className="text-center text-base"
                style={{
                  color:
                    getContrastYIQ(mainColor) !== 'black' ? 'white' : 'black',
                }}
              >
                {t('Don’t have an account')}
              </div>
              <div className="w-full flex justify-center items-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.location.href = `/${company}/register`;
                  }}
                  className="rounded-lg mt-4 text-xl font-bold px-4   p-2"
                  style={{
                    backgroundColor:
                      mainColor === color
                        ? getContrastYIQ(mainColor)
                        : mainColor,
                    color: mainColor,
                  }}
                >
                  {t('Register')}
                </button>
              </div>
            </Link>
          </form>
        </div>
      </Layout>
    </ThemeProvider>
  );
};

export default WebApp;
