import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

import { ReactComponent as Like } from 'assets/svg/thumb-up.svg';
import { ReactComponent as DisLike } from 'assets/svg/thumbsdown.svg';
import gate from 'gate';
import { RequestAnswer, ResponseAnswer } from 'types/api/widget';
import { useTheme } from 'styles/theme';
import { getContrastYIQ } from 'utils';

export interface QuizItemProps {
  data: any;

  answer: string | boolean;
  setQuestion: (data?: ResponseAnswer) => void;
}

type ButtonLoading = {
  True: boolean;
  False: boolean;
  key?: number;
  loading?: boolean;
};

type AnswerType = {
  description: string;
  success: boolean;
  showAnswer: boolean;
};
const QuizItem: React.FC<QuizItemProps> = ({ data, setQuestion, answer }) => {
  const { mainColor } = useTheme();
  const { t } = useTranslation();
  const [{ description, success, showAnswer }, setShowAnswer] = useState<
    AnswerType
  >({
    description: '',
    showAnswer: false,
    success: false,
  });

  const { backgroundColor } = useTheme();
  const { id } = useParams<any>();
  const [{ True, False, loading, key }, setbuttonLoading] = useState<
    ButtonLoading
  >({
    False: false,
    True: false,
    key: 0,
    loading: false,
  });
  const { question, choices, id: questionId } = data ?? {};
  const cache = useQueryClient();
  const { isLoading, mutate: postData } = useMutation(
    async (answer: RequestAnswer) => {
      try {
        const res = await gate.answerQuiz(id, answer);
        setShowAnswer({ ...(res as AnswerType) });
        setShowAnswer((prev) => {
          return { ...prev, showAnswer: true };
        });
        setbuttonLoading((prev) => {
          return { ...prev, loading: false };
        });
        cache.invalidateQueries(`widget/${id}`);
      } catch ({ data }) {
        toast.error(data.detail.response.messgae ?? 'err');

        if (data.detail.response.messgae === 'Already answered!') {
          setQuestion();
        }
      } finally {
        setbuttonLoading({ False: false, True: false });
      }
    },
  );
  const onAnswer = (answer: boolean | string, skip?: string | boolean) => {
    if (skip !== undefined) {
      return setQuestion();
    }

    postData({ answer: answer, questionId });
  };
  if (showAnswer) {
    return (
      <div
        style={{ backgroundColor }}
        className="max-w-600 flex justify-center mx-auto fixed inset-0 z-50 min-h-screen items-center "
      >
        <div className=" flex flex-col justify-center min-h-screen ">
          <div className="mb-10 w-full text-center font-bold text-24">
            {success ? t('correct') : t('incorrect')}
          </div>

          <div className="w-full mb-6 flex justify-center">
            {!success ? (
              <div className="text-black font-semibold opacity-75 text-9xl ">
                x
              </div>
            ) : (
              <div className="w-12 h-24 border-b-8 border-r-8 transform rotate-45 bg-transparent my-5 border-black " />
            )}
          </div>

          <div className="w-full p-10  text-18 opacity-75 ">{description} </div>
          <div className="w-full flex justify-center items-center">
            <Button
              style={{ backgroundColor: mainColor }}
              onClick={() => setQuestion()}
              className=" flex justify-center items-center rounded-full w-16 h-16 "
            >
              <div className="w-6 h-6 transform -rotate-45 border-b-4 border-r-4 border-white bg-transparent" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <div className="text-black font-bold text-2xl w-full text-center py-12"></div> */}
      <div className="w-full h-64 mb-10 relative flex justify-center items-center">
        <div className="bg-white w-64 h-64 transform -rotate-6 top-0 shadow-xs rounded-lg   absolute z-10 " />
        <div className="bg-white h-64 w-64  p-5 absolute top-0 z-20 text-18  shadow-xs rounded-lg text-gray-600">
          {question}
        </div>
      </div>
      {isEmpty(choices ?? {}) && (
        <div className=" flex pt-32 justify-around w-full px-16">
          <Button
            isLoading={isLoading && False}
            onClick={() => {
              setbuttonLoading({ False: true, True: false });
              onAnswer(false, answer);
            }}
            className={`bg-black ${
              answer === true && ' opacity-50 '
            } flex justify-center transition-all duration-300  items-center w-90 h-90 rounded-full hover:opacity-50`}
          >
            <DisLike />
          </Button>
          <Button
            className={`bg-black ${
              answer === false && ' opacity-50 '
            } flex justify-center transition-all duration-300  items-center w-90 h-90 rounded-full hover:opacity-50`}
            onClick={() => {
              setbuttonLoading({ False: false, True: true });
              onAnswer(true, answer);
            }}
            isLoading={isLoading && True}
          >
            <Like />
          </Button>
        </div>
      )}
      {choices?.map((option: unknown, index: number) => (
        <div key={index} className="px-10  py-2 w-full ">
          {console.log('answer:', answer === option, 'option:', option)}
          <Button
            spinnerColor={getContrastYIQ(mainColor)}
            isLoading={key === index && loading}
            onClick={() => {
              onAnswer(option as string, answer);
              setbuttonLoading((prev) => {
                return { ...prev, key: index, loading: true };
              });
            }}
            style={{
              backgroundColor: mainColor,
              color: getContrastYIQ(mainColor),
            }}
            className={` w-full ${
              answer && option !== answer && 'opacity-50'
            } flex justify-center items-center transition-all duration-300 hover:bg-white hover:text-black  py-3 text-center rounded-md `}
          >
            {option as string}
          </Button>
        </div>
      ))}
    </>
  );
};

export default QuizItem;
