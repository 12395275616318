import * as yup from 'yup';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
import { useForm, Controller, useWatch, FieldError } from 'react-hook-form';

import { useTheme } from 'styles/theme';
import { toast } from 'react-toastify';
import { RequestEditMe } from 'types/api/user';

import { Button, Input, LoadingPage, Cropper } from 'components';
import gate from 'gate';

import placeholder from 'assets/images/avatar-placeholder.png';
import { ReactComponent as Pencil } from 'assets/svg/pencil.svg';
import { ReactComponent as Back } from 'assets/svg/back.svg';
import ResetPassword from './ResetPassword';
import { ResponseUploadFile } from 'types/api/default';
import { getContrastYIQ, getDownloadAddress } from 'utils';

const Settings: React.FC = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup.string().required(t('field is required')),
    lastName: yup.string().required(t('field is required')),
    function: yup.string().required(t('field is required')),
    email: yup.string().email(t('invalid email')),
  });

  const [avatarFile, setAvatarFile] = useState();
  const [avatar, setAvatar] = useState<ResponseUploadFile>({
    filename: '',
    url: '',
  });
  const cache = useQueryClient();
  const { data: meData, isLoading: meDataLoading } = useQuery('me', () =>
    gate.me(),
  );
  const { mainColor, backgroundColor } = useTheme();

  const [edit, setEdit] = useState<boolean>(false);
  const { mutate: editMe, isLoading } = useMutation(
    async (data: RequestEditMe) => {
      try {
        await gate.editMe(data);
        setEdit(false);
      } catch ({ data }) {
        toast.error(data?.message);
      }
    },
    {
      onSuccess: () => {
        cache.invalidateQueries('me');
        cache.invalidateQueries('home');
      },
    },
  );
  const defaultValues: RequestEditMe = {
    avatar: '',
    email: '',
    firstName: '',
    function: '',
    lastName: '',
    startTime: '',
  };
  const { control, setValue, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (meData) {
      setValue('firstName', meData?.firstName);
      setValue('lastName', meData?.lastName);
      setValue('function', meData?.function ?? '');
      setValue('email', meData?.email);
    }
  }, [meDataLoading, meData]);

  const { startTime } = useWatch({ control });
  const onSubmit = (_data: RequestEditMe) => {
    editMe({
      ..._data,
      startTime: startTime
        ? new Date(
            `${(startTime as DayValue)?.year}/${
              (startTime as DayValue)?.month
            }/${(startTime as DayValue)?.day}`,
          )
        : meData?.startTime,
      ...(avatar.url && { avatar: avatar.url }),
    });
  };
  if (meDataLoading) {
    return <LoadingPage />;
  }

  return (
    <div className=" animate-fadeRight flex flex-col min-h-screen">
      <div className="w-full p-8 flex   justify-between items-center ">
        <Link to="/profile">
          <div className="p-3">
            <Back fill={getContrastYIQ(backgroundColor)} />
          </div>
        </Link>
      </div>
      <div className="w-full flex justify-center items-center text-2xl text-center font-bold">
        {meData
          ? capitalize((meData as RequestEditMe)?.firstName) +
            ' ' +
            capitalize((meData as RequestEditMe).lastName)
          : ''}
      </div>
      <label className="cursor-pointer" htmlFor="avatar">
        <div className="w-full pt-5 relative justify-center items-center flex">
          <img
            className="w-90 h-90 object-cover rounded-full"
            src={
              avatar.url !== ''
                ? avatar.url
                : meData?.avatar
                ? getDownloadAddress(meData?.avatar)
                : placeholder
            }
            alt="..."
          />
        </div>
      </label>
      <div className="w-full flex-col justify-center items-center flex">
        <form className="w-8/12 " onSubmit={handleSubmit(onSubmit)}>
          <div className="flex pb-8 w-full justify-center items-center pl-48 ">
            {!edit ? (
              <Button
                className="h-10 overflow-hidden "
                onClick={(e) => {
                  e.preventDefault();
                  setEdit(!edit);
                }}
              >
                <Pencil />
              </Button>
            ) : (
              <Button
                isLoading={isLoading}
                type="submit"
                className="h-10 overflow-hidden "
              >
                {t('save')}
              </Button>
            )}
            <input
              onChange={(e) => setAvatarFile(e as any)}
              className="hidden"
              type="file"
              disabled={!edit}
              id="avatar"
              name="avatar"
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <Controller
              readOnly={!edit}
              as={Input}
              style={{
                backgroundColor:
                  getContrastYIQ(backgroundColor) === 'black'
                    ? '#edf2f7'
                    : 'white',
              }}
              className="w-full"
              inputClassName="  rounded-2xl border-none p-3"
              name="firstName"
              title={t('First Name')}
              error={((errors.firstName as unknown) as FieldError)?.message}
              control={control}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <Controller
              readOnly={!edit}
              as={Input}
              style={{
                backgroundColor:
                  getContrastYIQ(backgroundColor) === 'black'
                    ? '#edf2f7'
                    : 'white',
              }}
              className="w-full"
              inputClassName="  rounded-2xl border-none p-3"
              name="lastName"
              title={t('Last Name')}
              control={control}
              error={((errors.lastName as unknown) as FieldError)?.message}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <Controller
              as={Input}
              readOnly={!edit}
              className="w-full"
              style={{
                backgroundColor:
                  getContrastYIQ(backgroundColor) === 'black'
                    ? '#edf2f7'
                    : 'white',
              }}
              inputClassName="  rounded-2xl border-none p-3"
              name="email"
              title={t('Email')}
              error={((errors.email as unknown) as FieldError)?.message}
              control={control}
            />
          </div>
          <div className="w-full flex justify-center items-center">
            <Controller
              as={Input}
              readOnly={!edit}
              className="w-full"
              inputClassName="  rounded-2xl border-none p-3"
              name="function"
              title={t('Function')}
              error={((errors.function as unknown) as FieldError)?.message}
              style={{
                backgroundColor:
                  getContrastYIQ(backgroundColor) === 'black'
                    ? '#edf2f7'
                    : 'white',
              }}
              control={control}
            />
          </div>
          <div className="w-full flex flex-col justify-start ">
            <label className="block pb-1 text-left font-bold ">
              {t('Start Time')}
            </label>
            <Controller
              render={({ onChange, ...props }) => (
                <DatePicker
                  wrapperClassName="w-full"
                  onChange={onChange}
                  {...props}
                  colorPrimary={mainColor}
                  renderInput={({ ref }: any) => (
                    <input
                      disabled={!edit}
                      value={
                        startTime
                          ? `${(startTime as DayValue)?.year}/${
                              (startTime as DayValue)?.month
                            }/${(startTime as DayValue)?.day}`
                          : meData?.startTime?.substr(0, 10)
                      }
                      readOnly
                      style={{
                        backgroundColor:
                          getContrastYIQ(backgroundColor) === 'black'
                            ? '#edf2f7'
                            : 'white',
                      }}
                      className=" w-full  rounded-2xl border-none p-3"
                      ref={ref}
                    />
                  )}
                />
              )}
              control={control}
              name="startTime"
            />
            {/* <p className="text-xs h-4 p-2 w-full text-center  text-red-600">
              {((errors.function as unknown) as FieldError)?.message}
            </p> */}
          </div>
        </form>
        <ResetPassword />
      </div>
      <Cropper file={avatarFile} setResponse={(res) => setAvatar(res)} />
    </div>
  );
};

export default Settings;
