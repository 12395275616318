import React from 'react';
export interface HomeIconProps {
  className?: string;
  fill?: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({
  className = 'w-6 h-6',
  fill = 'white',
}) => {
  return (
    <svg
      className={className}
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 15L15 2L29 15" stroke={fill} strokeWidth="2" />
      <path d="M4 12V25H12V15H18V25H26V12" stroke={fill} strokeWidth="2" />
    </svg>
  );
};

export default HomeIcon;
