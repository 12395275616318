import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  HorizontalList,
  LoadingPage,
  Progress,
  Spinner,
  WidgetItem,
} from 'components';
import { useTheme } from 'styles/theme';

import gate from 'gate';
import { getDownloadAddress } from 'utils';
import { CategoryType, RequestHome } from 'types/api/default';
type urlType = {
  _id: number;
  completed: boolean;
};
type accessibleType = {
  accessible: boolean;
  status: string | number | null;
};
type calculateIsAccessiblityType = (
  deadLine: number,
  accessible: CategoryType['accessible'],
  statusPreviousCategoryCompleted?: boolean,
) => accessibleType;
type calculateDeadLineType = (
  deadLine: number,
  _accessible: boolean,

  day: number,
  status: any,
) => { _accessible: boolean; status: string };

const Home: React.FC = () => {
  const { t } = useTranslation();
  const router = useHistory();

  const { backgroundColor, mainColor } = useTheme();
  const [{ _id, completed }, setUrl] = useState<urlType>({
    _id: 0,
    completed: false,
  });
  const cache = useQueryClient();

  const { mutate: completeWidget } = useMutation(
    async (id: number) => {
      try {
        await gate.completeWidget(Number(id));
      } catch (error) {}
    },
    {
      onSuccess: () => cache.invalidateQueries('home'),
    },
  );
  const { isLoading: widgetLoading, data: widgetData } = useQuery(
    'widget/' + _id,
    async () => await gate.widget(_id),

    {
      enabled: !!_id,
    },
  );
  const { isLoading: loading, data } = useQuery<RequestHome>(
    'home',
    async () => await gate.home(),
  );
  useEffect(() => {
    if (widgetData) {
      if (!completed) {
        completeWidget(widgetData.id);
      }
      const url =
        (widgetData?.content?.url as string)?.includes('http') ||
        (widgetData?.content?.url as string)?.includes('https')
          ? widgetData?.content?.url
          : 'https://' + widgetData?.content?.url;
      window.open(url);
      setUrl({ _id: 0, completed: false });
    }
  }, [widgetData, widgetLoading]);
  const { firstName, startTime } = data ?? {};
  const { header, categories } = data?.department ?? {};

  const categories_ = categories?.length ? [...categories] : [];
  categories_.sort((a, b) => (a.id > b.id ? 1 : -1));

  if (loading) return <LoadingPage />;

  const date =
    startTime && new Date(startTime)?.getTime() - new Date().getTime();
  const day = (date && Math.round(date / 86400000)) ?? -1;
  const calculateDeadLine: calculateDeadLineType = (
    deadLine,
    _accessible,
    _day,
    status,
  ) => {
    if (deadLine > 0) {
      _accessible = deadLine + day >= 0;
      // console.log(day,)
      console.log(deadLine + day, -5 + 14, deadLine, day);
      console.log('deadLine:', deadLine);
      status = !_accessible
        ? `${Math.abs(day + deadLine)} days past due date of completion`
        : '';
    }

    if (deadLine < 0) {
      _accessible = deadLine + day >= 0;
      // console.log()
      status = !_accessible
        ? `${Math.abs(day + deadLine)} days past due date of completion`
        : '';
    }
    return { _accessible, status };
  };

  const calculateIsAccessiblity: calculateIsAccessiblityType = (
    deadLine,
    accessible,
    statusPreviousCategoryCompleted,
  ) => {
    let _accessible = false;
    let status = null;
    if (accessible === 'ALWAYS') {
      _accessible = deadLine <= 0;
      ({ _accessible, status } = calculateDeadLine(
        deadLine,
        _accessible,
        day,
        status,
      ));

      return { accessible: _accessible, status };
    }

    if (accessible === 'FIRST_DAY') {
      _accessible = day <= 0;

      ({ _accessible, status } = calculateDeadLine(
        deadLine,
        _accessible,
        day,
        status,
      ));
      if (day > 0) {
        _accessible = false;
      }
      return {
        accessible: _accessible,
        status,
      };
    }

    if (statusPreviousCategoryCompleted) {
      if (accessible === 'COMPLETION_PREVIOUS_PHASE') {
        _accessible = day + deadLine >= 0;
        status = !_accessible
          ? `${Math.abs(deadLine + day)} days past due date of completion`
          : '';

        ({ _accessible, status } = calculateDeadLine(
          deadLine,
          _accessible,
          day,
          status,
        ));
        return { accessible: _accessible, status };
      }
    }
    if (deadLine > 0) {
      _accessible = day - deadLine <= 0;
      status = `${Math.abs(day + deadLine)} days past due date of completion`;
    }
    return { accessible: false, status: null };
  };

  return (
    <div className=" min-h-screen   w-full animate-fadeRight  flex flex-col ">
      <div className="w-full  h-64 justify-center md:h-350 lg:h-384  items-center relative">
        <div
          style={{
            backgroundImage: ` linear-gradient(to bottom, rgba(0,0,0,0), ${backgroundColor})`,
          }}
          className=" h-64 md:h-350 lg:h-384  absolute -bottom-10  z-20  w-full "
        ></div>
        <img
          src={getDownloadAddress(header ?? '')}
          className="w-full h-64  md:h-350 lg:h-384 object-cover absolute  z-0 top-0"
          alt="..."
        />
      </div>
      <div
        style={{ backgroundColor }}
        className="w-full py-2 text-center z-30   -mt-1    font-bold text-36 "
      >
        {t('welcome')} {capitalize(firstName)}
      </div>

      <div className="flex flex-col justify-center  items-center">
        {categories_?.map(
          (
            { categoryWidgets, accessible, deadline, name, id }: CategoryType,
            index: number,
          ) => {
            const filterCompleteds = categoryWidgets.filter(
              ({ categoryWidgetUsers }) => categoryWidgetUsers[0]?.isCompleted,
            );
            const progressPercent =
              Math.floor(
                (filterCompleteds.length / categoryWidgets.length) * 100,
              ) || 0;

            const prevCategoryStatus = categories_[index - 1]
              ? categories_[index - 1].categoryWidgets.filter(
                  ({ categoryWidgetUsers }) =>
                    categoryWidgetUsers[0]?.isCompleted,
                ).length === categories_[index - 1].categoryWidgets?.length
              : false;

            const {
              status,
              accessible: isAccessible,
            } = calculateIsAccessiblity(
              deadline,
              accessible,
              prevCategoryStatus,
            );
            return (
              <div
                className={`${!isAccessible && 'opacity-75'} w-full `}
                key={id}
              >
                <div className="w-full  px-2 flex flex-col items-center">
                  <div className="w-full whitespace-no-wrap flex justify-between pt-6 items-center">
                    <div
                      className={`md:text-36  font-bold ${
                        name?.length < 9 ? 'text-21' : 'text-14 md:text-18'
                      }`}
                    >
                      {name}
                    </div>
                    <div className="   flex flex-col text-12  sm:text-14 md:text-base">
                      <Progress
                        borderColor={mainColor}
                        percet={progressPercent}
                      />
                      <div className="text-10">{status}</div>
                    </div>
                  </div>
                </div>
                <div className="w-full  mt-2">
                  <HorizontalList
                    className="py-2"
                    data={categoryWidgets}
                    renderItem={(
                      { categoryWidgetUsers, thumbnail, text, type, id, title },
                      index,
                    ) => {
                      const completed = categoryWidgetUsers[0]?.isCompleted;

                      if (type === 'Url') {
                        return (
                          <button
                            disabled={!isAccessible}
                            onClick={() =>
                              setUrl({
                                _id: id,
                                completed: !!completed,
                              })
                            }
                            className="w-full cursor-pointer  p-2"
                          >
                            {widgetLoading && id === _id ? (
                              <div
                                style={{ backgroundColor: mainColor }}
                                className="rounded-lg flex justify-center items-center object-cover shadow-md min-w-150 w-150 h-120"
                              >
                                <Spinner className="w-10 h-10 " fill="white" />
                              </div>
                            ) : (
                              <WidgetItem
                                lastItemColor={
                                  index % 2 === 0 ? 'black' : 'white'
                                }
                                text={text}
                                tick={completed}
                                imgUrl={
                                  thumbnail && getDownloadAddress(thumbnail)
                                }
                                title={title}
                              />
                            )}
                          </button>
                        );
                      }
                      return (
                        <button
                          disabled={!isAccessible}
                          onClick={() =>
                            router.push(
                              `/widget/${id}?footer=false&completed=${!!completed}&type=${type}`,
                            )
                          }
                        >
                          <div className="w-full  p-2">
                            <WidgetItem
                              lastItemColor={
                                index % 2 === 0 ? 'black' : 'white'
                              }
                              text={text}
                              tick={!!completed}
                              imgUrl={
                                thumbnail && getDownloadAddress(thumbnail)
                              }
                              title={title}
                            />
                          </div>
                        </button>
                      );
                    }}
                  />
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default Home;
