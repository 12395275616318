import React from 'react';
export interface LoveIconProps {
  active?: boolean;
}

const LoveIcon: React.FC<LoveIconProps> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? '1' : '0.195359'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M5.94397 15.5C7.15497 17.59 9.41097 19 12 19C14.589 19 16.845 17.59 18.056 15.5"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M9.5 7C8.9641 7 8.46891 7.2859 8.20096 7.75C7.93301 8.2141 7.93301 8.7859 8.20096 9.25C8.46891 9.7141 8.9641 10 9.5 10C10.0359 10 10.5311 9.7141 10.799 9.25C11.067 8.7859 11.067 8.2141 10.799 7.75C10.5311 7.2859 10.0359 7 9.5 7V7Z"
          fill="black"
        />
        <path
          d="M6.5 7C5.9641 7 5.46891 7.2859 5.20096 7.75C4.93301 8.2141 4.93301 8.7859 5.20096 9.25C5.46891 9.7141 5.9641 10 6.5 10C7.0359 10 7.53109 9.7141 7.79904 9.25C8.06699 8.7859 8.06699 8.2141 7.79904 7.75C7.53109 7.2859 7.0359 7 6.5 7V7Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.201 7.75C16.4689 7.2859 16.9641 7 17.5 7C18.0359 7 18.5311 7.2859 18.799 7.75C19.067 8.2141 19.067 8.7859 18.799 9.25C18.5311 9.7141 18.0359 10 17.5 10C16.9641 10 16.4689 9.7141 16.201 9.25C15.933 8.7859 15.933 8.2141 16.201 7.75ZM8 12C8 12 5 10 5 8.5H11C11 10.094 8 12 8 12Z"
          fill="black"
        />
        <path
          d="M14.5 7C13.9641 7 13.4689 7.2859 13.201 7.75C12.933 8.2141 12.933 8.7859 13.201 9.25C13.4689 9.7141 13.9641 10 14.5 10C15.0359 10 15.5311 9.7141 15.799 9.25C16.067 8.7859 16.067 8.2141 15.799 7.75C15.5311 7.2859 15.0359 7 14.5 7V7Z"
          fill="black"
        />
        <path
          d="M13 8.5C13 10 16 12 16 12C16 12 19 10.094 19 8.5H13Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export default LoveIcon;
