import React from 'react';
export interface SadIconProps {
  active?: boolean;
}

const SadIcon: React.FC<SadIconProps> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? '1' : '0.195359'}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M17.665 17.916C16.395 16.155 14.338 15 12 15C9.66202 15 7.60502 16.155 6.33502 17.916"
          stroke="black"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 9.5C6 8.67157 6.67157 8 7.5 8C8.32843 8 9 8.67157 9 9.5C9 10.3284 8.32843 11 7.5 11C6.67157 11 6 10.3284 6 9.5ZM15 9.5C15 8.67157 15.6716 8 16.5 8C17.3284 8 18 8.67157 18 9.5C18 10.3284 17.3284 11 16.5 11C15.6716 11 15 10.3284 15 9.5Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
export default SadIcon;
