import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, PDFReader } from 'components';

import { ReactComponent as Back } from 'assets/svg/back.svg';
import { useTheme } from 'styles/theme';
interface PropsTypes {
  url: string;
}
const PDFWidget: FC<PropsTypes> = ({ url }) => {
  const router = useHistory();

  const { mainColor } = useTheme();
  return (
    <div className="flex flex-col">
      <div className="absolute top-0 justify-start p-4">
        <Button onClick={() => router.goBack()}>
          <Back fill={mainColor} />
        </Button>
      </div>
      {url && url !== '' ? (
        <PDFReader url={url} />
      ) : (
        <div className="flex items-center justify-center min-h-screen">
          loading...
        </div>
      )}
    </div>
  );
};

export default PDFWidget;
