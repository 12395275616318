import { RequestHome } from 'types/api/default';
import { RequestMe } from 'types/api/user';
import {
  RequestAnswer,
  RequestCompleteWidget,
  RequestDoneTodo,
} from 'types/api/widget';
import api from './api';
export default {
  companyInfo: (company: string) => api.get(`/staff/${company}/info`),
  companyDetails: (company: string) => api.get(`/staff/${company}/details`),
  home: () => api.get<RequestHome>('/staff/home'),
  faq: () => api.get('/staff/faq'),
  widget: (id: number) => api.get('/staff/widget/' + id),
  answerQuiz: (id: number, data: RequestAnswer) =>
    api.post(`/staff/widget/${id}/quiz/answer`, data),
  completeWidget: (
    id: number,
    data: { isCompleted: boolean } = { isCompleted: true },
  ) => api.post(`/staff/widget/${id}/complete`, data),
  todoWidgetDone: (id: number, data: RequestDoneTodo) =>
    api.post(`/staff/widget/${id}/todo/done`, data),
};
