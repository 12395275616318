import React from 'react';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserHistory } from 'history';

import store from 'store/index';
import 'styles/index.css';
import 'styles/progress.css';
import 'cropperjs/dist/cropper.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'keen-slider/keen-slider.min.css';
import './i18n';
import Routes from 'routes';

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  },
});
export const history = createBrowserHistory();
function App() {
  return (
    <QueryClientProvider client={queryCache}>
      <Provider store={store}>
        <Router history={history}>
          <ToastContainer
            bodyClassName="toast-body"
            position="top-center"
            autoClose={3000}
            hideProgressBar
            closeButton={false}
            toastClassName="rounded-full m-2"
            newestOnTop
            closeOnClick
            transition={Slide}
          />
          <Routes />
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
