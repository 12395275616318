import React from 'react';
export interface HomeIconProps {
  className?: string;
  fill?: string;
}

const InfoIcon: React.FC<HomeIconProps> = ({
  className = 'w-6 h-6',
  fill = 'white',
}) => {
  return (
    <svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 19V11"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M14 8V6H12V8H14ZM13 0C5.8203 0 0 5.8203 0 13H2C2 6.92487 6.92487 2 13 2V0ZM0 13C0 20.1797 5.8203 26 13 26V24C6.92487 24 2 19.0751 2 13H0ZM13 26C20.1797 26 26 20.1797 26 13H24C24 19.0751 19.0751 24 13 24V26ZM26 13C26 5.8203 20.1797 0 13 0V2C19.0751 2 24 6.92487 24 13H26Z"
        fill={fill}
      />
    </svg>
  );
};

export default InfoIcon;
