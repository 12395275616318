import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Back } from 'assets/svg/back.svg';
import { QuizItem } from '..';
import { getContrastYIQ } from 'utils';
import { useTheme } from 'styles/theme';
import { capitalize } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import gate from 'gate';

export interface QuizWidgetProps {
  data: any;
}

const QuizWidget: React.FC<QuizWidgetProps> = ({ data }) => {
  const { content: questions, title = '' } = data ?? [];
  const router = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const completed = new URLSearchParams(location.search).get('completed');
  const { mainColor } = useTheme();
  const [question, setQuestion] = useState<number>(0);
  const cache = useQueryClient();

  const handleProgress = (question: number) => {
    const totalQuestion = questions.length ?? 0;
    return Math.floor((question / totalQuestion) * 100);
  };

  const { mutate: completeWidget } = useMutation(async () => {
    try {
      await gate.completeWidget(Number(id));
      cache.invalidateQueries('home');
      cache.invalidateQueries(`widget/${id}`);
    } catch (error) {}
  });
  useEffect(() => {
    if (question === questions.length || completed === 'fasle') {
      completeWidget();
    }
  }, [question]);

  if (question === questions.length) {
    return (
      <div className=" flex flex-col justify-center min-h-screen ">
        <div className="mb-10 w-full text-center font-bold text-24">done</div>

        <div className="w-full mb-6 flex justify-center">
          <div className="w-12 h-24 border-b-8 border-r-8 transform rotate-45 bg-transparent my-5 border-black " />
        </div>

        <div className="h-16" />

        <div className="w-full flex justify-center items-center">
          <Button
            onClick={() => router.push('/')}
            className="bg-black flex justify-center mt-10 items-center rounded-full w-16 h-16 "
          >
            <div className="w-6 h-6 transform -rotate-45 border-b-4 border-r-4 border-white bg-transparent" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="full pb-32 pt-4 relative min-h-screen flex flex-col justify-start items-center ">
      <div className="w-full justify-start p-4">
        <Button onClick={() => router.goBack()}>
          <Back
            fill={getContrastYIQ(mainColor) === 'black' ? 'white' : 'black'}
          />
        </Button>
      </div>
      <div className="font-bold">{question + '/' + questions.length}</div>
      <div className="w-full ske flex  my-2 relative  bg-gray-450  text-white text-center items-center justify-start">
        <div
          style={{ width: `${handleProgress(question)}%` }}
          className="bg-black transition-all duration-500  h-5 rounded-tr-full rounded-br-full "
        ></div>
        <div
          style={{ mixBlendMode: 'difference' }}
          className="text-white text-14 font-bold absolute z-10 w-full text-center "
        >
          {handleProgress(question)}%
        </div>
      </div>
      <h2 className="py-10">{capitalize(title)}</h2>
      {questions.map((_question: any, index: number) => {
        if (question === index)
          return (
            <QuizItem
              setQuestion={(data) => {
                setQuestion((prev) => {
                  prev++;
                  return prev;
                });
              }}
              answer={_question.answer}
              key={_question.id}
              data={_question}
            />
          );
      })}
    </div>
  );
};

export default QuizWidget;
