import React, { FC, useEffect, useRef } from 'react';
// import WebViewer from '@pdftron/pdfjs-express';

// interface Props {
//   url: string;
// }

const PDFReader = ({ url }) => {
  // const viewer = useRef(null);

  // // if using a class, equivalent of componentDidMount
  // useEffect(() => {
  //   WebViewer(
  //     {
  //       // path: '/webviewer/lib',
  //       initialDoc: url,
  //     },
  //     viewer.current,
  //   ).then((instance) => {
  //     const { docViewer, Annotations } = instance;
  //     const annotManager = docViewer.getAnnotationManager();

  //     docViewer.on('documentLoaded', () => {
  //       const rectangleAnnot = new Annotations.RectangleAnnotation();
  //       rectangleAnnot.PageNumber = 1;
  //       // values are in page coordinates with (0, 0) in the top left
  //       rectangleAnnot.X = 100;
  //       rectangleAnnot.Y = 150;
  //       rectangleAnnot.Width = 200;
  //       rectangleAnnot.Height = 50;
  //       rectangleAnnot.Author = annotManager.getCurrentUser();

  //       annotManager.addAnnotation(rectangleAnnot);
  //       // need to draw the annotation otherwise it won't show up until the page is refreshed
  //       annotManager.redrawAnnotation(rectangleAnnot);
  //     });
  //   });
  // }, []);

  return (
    // <iframe
    //   // src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
    //   srcDoc={url}
    //   frameBorder="0"
    //   width="100%"
    //   className="min-h-screen"
    // ></iframe>

    <object
      data={url}
      type="application/pdf"
      height="100%"
      width="100%"
      // onError={(e) => console.log(2, e)}
      // onErrorCapture={(e) => console.log(231, e)}
      // onLoadedData={(e) => console.log(232, e)}
      // onLoad={(e) => console.log(233, e)}
    >
      {/* // onError={(e) => console.log(3, e)}
        // onErrorCapture={(e) => console.log(31, e)}
        // onLoadedData={(e) => console.log(32, e)}
        // onLoad={() => alert('loaded')}
        // src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`}
        // src={`https://docs.google.com/viewer?url=${url}&embedded=true`} */}
      <div
        className="min-h-screen"
        dangerouslySetInnerHTML={{
          __html: `<iframe type='application/pdf' src="https://docs.google.com/viewerng/viewer?url=${url}&embedded=true" frameBorder='0' width='100%' class='min-h-screen'></iframe>`,
        }}
      />
      ;
    </object>

    // <div className="webviewer" ref={viewer}></div>

    //   const [error, setError] = useState<boolean>(false);
    // const [numPages, setNumPages] = useState<number>(0);
    // const [pageNumber, setPageNumber] = useState<number>(1);
    // function onDocumentLoadSuccess({ numPages }: any) {
    //   setNumPages(numPages as number);
    // }

    // const downloadFile = (filename: string, url: string) => {
    //   var element = document.createElement('a');
    //   element.setAttribute('href', url);
    //   element.setAttribute('download', filename);

    //   element.style.display = 'none';
    //   document.body.appendChild(element);

    //   element.click();

    //   document.body.removeChild(element);
    // };

    // const onError = () => {
    //   setError(true);
    //   const name = url.split('/');

    //   downloadFile(name[name.length - 1], url);

    //   // fetch(url)
    //   //   .then((resp) => resp.blob())
    //   //   .then((blob) => {
    //   //     const url = window.URL.createObjectURL(blob);
    //   //     const a = document.createElement('a');
    //   //     a.style.display = 'none';
    //   //     a.href = url;
    //   //     // the filename you want
    //   //     a.download = name[name.length - 1];
    //   //     document.body.appendChild(a);
    //   //     a.click();
    //   //     window.URL.revokeObjectURL(url);
    //   //     // alert('your file has downloaded!'); // or you know, something with better UX...
    //   //   })
    //   //   .catch(() => {
    //   //     // alert('oh no!')
    //   //   });
    // };

    // const PdfLoadError = () => {
    //   return (
    //     <div className="react-pdf__message react-pdf__message--error flex justify-center items-center h-screen">
    //       Failed to load PDF file.
    //       <br /> Downloading now...
    //     </div>
    //   );
    // };

    // return (
    //   <>
    //     <Document
    //       className="overflow-scroll bg-white scrollbar-none  right-0 min-h-screen"
    //       file={url}
    //       error={<PdfLoadError />}
    //       loading={<LoadingPage />}
    //       onLoadSuccess={onDocumentLoadSuccess}
    //       onLoadError={onError}
    //       onSourceError={onError}
    //     >
    //       <Page pageNumber={pageNumber} />
    //     </Document>
    //     {!error && (
    //       <div className="flex absolute w-full bottom-0 py-5 justify-center items-center">
    //         <button
    //           onClick={() =>
    //             setPageNumber((prev) => {
    //               if (prev === 1) {
    //                 return prev;
    //               }
    //               prev--;
    //               return prev;
    //             })
    //           }
    //           className="w-4 h-4 border-l-4 mx-2 rounded-r-md rounded-t-md transform rotate-45 border-b-4 border-black "
    //         />
    //         <div className="font-bold text-black">
    //           Page {pageNumber} of {numPages}
    //         </div>

    //         <button
    //           onClick={() =>
    //             setPageNumber((prev) => {
    //               if (prev === numPages) {
    //                 return prev;
    //               }
    //               prev++;
    //               return prev;
    //             })
    //           }
    //           className="w-4 h-4 border-r-4 mx-2 rounded-l-md rounded-b-md transform rotate-45 border-t-4 border-black "
    //         />
    //       </div>
    //     )}
    //   </>
  );
};

export default PDFReader;
