import gate from 'gate';
import React from 'react';
import { useQuery } from 'react-query';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styles/theme';

import { Layout } from 'components';

import routes from './routes';

const App: React.FC = () => {
  const { companyColor: defaultCompanyColor } = useTheme();
  const { search } = useLocation();

  const location = useLocation();
  const footer = new URLSearchParams(search).get('footer');
  const { data } = useQuery('home', async () => await gate.home());
  const { backgroundColor, mainColor, accentColor } = data?.department ?? {};

  console.log('version', 1);
  return (
    <ThemeProvider
      theme={{
        mainColor,
        backgroundColor,
        IconColor: accentColor,
        companyColor: defaultCompanyColor,
      }}
    >
      <Layout footer={footer === 'false' ? false : true}>
        <Switch>
          {routes.map(({ exact, path, component }) => (
            <Route
              exact={exact}
              key={String(path)}
              path={String(path)}
              component={component}
            />
          ))}

          <Redirect to="/" />
        </Switch>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
